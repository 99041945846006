import React from "react";
import { useSelector } from "react-redux";

const MatchInningDetails = ({ team }) => {
  const { matchDetails } = useSelector(({ match: { matchDetails } }) => ({
    matchDetails,
  }));

  function getOver(ballsPlayed = 0) {
    if (ballsPlayed % 6 === 0) {
      return ballsPlayed / 6;
    }
    return `${Math.floor(ballsPlayed / 6)}.${Math.floor(ballsPlayed % 6)}`;
  }

  function renderTimeline() {
    let overs = matchDetails.team_2_overs;
    let arr = [];
    if (overs && overs.length > 2) {
      overs = [overs[overs.length - 2], overs[overs.length - 1]];
    }
    overs.forEach((over) => {
      over.balls.forEach((ball) => {
        if (ball.wide) {
          arr.push(
            <div className="ball_container">
              <span className="ball">{ball.score + "wd"}</span>
            </div>
          );
        } else if (ball.no_ball) {
          arr.push(
            <div className="ball_container">
              <span className="ball">{ball.score + "nb"}</span>
            </div>
          );
        } else if (ball.leg_bye) {
          arr.push(
            <div className="ball_container">
              <span className="ball">{ball.score + "lb"}</span>
            </div>
          );
        } else if (ball.bye) {
          arr.push(
            <div className="ball_container">
              <span className="ball">{ball.score + "b"}</span>
            </div>
          );
        } else if (ball.out) {
          arr.push(
            <div className="ball_container">
              <span className="ball" style={{ color: "red" }}>
                {ball.score != 0 ? ball.score + "W" : "W"}
              </span>
            </div>
          );
        } else {
          arr.push(
            <div className="ball_container">
              <span className="ball">{ball.score}</span>
            </div>
          );
        }
      });
      if (!over.active) {
        arr.push(<span className="over_seperator">{"|"}</span>);
      }
    });
    return arr;
  }

  return (
    <div className="inning-card">
      <div className="top-meta-details is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-3 mt-5">
        <p className="is-inline-block pc is-size-6 has-text-weight-bold">
          {matchDetails.team_2.name}
        </p>
        {/* <img
          className="match-team-logo"
          src={matchDetails.team_2.image}
          alt="delhi-avengers" /> */}
        <span className="is-inline-block pc is-size-7 has-text-weight-bold">
          {matchDetails.team_2_stats.score}/{matchDetails.team_2_stats.out}
          <span className="has-text-grey has-text-weight-medium is-size-8">
            &nbsp;({getOver(matchDetails.team_2_stats.balls)})
          </span>
          &nbsp;
          <span className="pc has-text-weight-medium is-size-8">
            RR{" "}
            {matchDetails.team_2_stats.balls
              ? (
                  matchDetails.team_2_stats.score /
                  getOver(matchDetails.team_2_stats.balls)
                ).toFixed(2)
              : "0.00"}
          </span>
        </span>
      </div>
      <p className="is-size-8 pc has-text-weight-semibold">Recent balls</p>
      {matchDetails.team_2_overs.length > 0 ? (
        <div className="ballTimeline">{renderTimeline()}</div>
      ) : null}
      <div className="match-inning-score-list">
        <div className="match-inning-score-header is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-2">
          <div className="match-inning-score-header-item w-50">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              Batting
            </span>
          </div>
          <div className="match-inning-score-header-item w-15">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              R/B
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              4s
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              6s
            </span>
          </div>
          <div className="match-inning-score-header-item w-15">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              SR
            </span>
          </div>
        </div>
        {matchDetails.team_2_players.map((batter, index) => (
          <div
            key={index.toString()}
            className={
              index % 2 === 0
                ? "match-inning-score-row is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center even"
                : "match-inning-score-row is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
            }
          >
            <div className="match-inning-score-header-item w-50">
              <span className="is-block pc is-size-8 has-text-weight-semibold">
                {batter.name}
              </span>
              <span
                className={`is-block is-size-8 ${
                  batter.out ? "sc" : "has-text-primary-dark"
                }`}
              >
                {batter.out
                  ? `${batter.out_type.toLowerCase()} - ${
                      batter?.fielder?.name || ""
                    }`
                  : "not out"}
              </span>
            </div>
            <div className="match-inning-score-header-item w-15">
              <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                {batter.stats.score}{" "}
                <span className="has-text-grey is-size-8 has-text-weight-medium">
                  ({batter.stats.balls})
                </span>
              </span>
            </div>
            <div className="match-inning-score-header-item w-10">
              <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                {batter.stats.boundary}
              </span>
            </div>
            <div className="match-inning-score-header-item w-10">
              <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                {batter.stats.six}
              </span>
            </div>
            <div className="match-inning-score-header-item w-15">
              <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                {batter.stats.balls
                  ? ((batter.stats.score / batter.stats.balls) * 100).toFixed(2)
                  : "0.00"}
              </span>
            </div>
          </div>
        ))}
        <div className="match-innings-extras mt-3 mb-3">
          <p className="is-block pc is-size-7 has-text-weight-semibold">
            Extras
          </p>
          <p className="is-inline-block pc is-size-8 has-text-weight-medium">
            (nb {matchDetails.team_2_stats.no_ball}, b{" "}
            {matchDetails.team_2_stats.bye}, w {matchDetails.team_2_stats.wide},
            lb {matchDetails.team_2_stats.leg_bye})
          </p>
        </div>

        <div className="match-inning-score-header is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-2">
          <div className="match-inning-score-header-item w-50">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              Bowling
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              O
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              R
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              W
            </span>
          </div>
          <div className="match-inning-score-header-item w-10">
            <span className="is-inline-block pc is-size-7 has-text-weight-bold">
              Eco
            </span>
          </div>
        </div>
        {matchDetails.team_1_players
          .filter((bowler) => !!bowler.stats.balling_balls)
          .map((bowler, index) => {
            if (bowler.stats.balling_balls) {
              return (
                <div
                  key={index.toString()}
                  className={
                    index % 2 === 0
                      ? "match-inning-score-row is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center even"
                      : "match-inning-score-row is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
                  }
                >
                  <div className="match-inning-score-header-item w-50">
                    <span className="is-block pc is-size-8 has-text-weight-semibold">
                      {bowler.name}
                    </span>
                  </div>
                  <div className="match-inning-score-header-item w-10">
                    <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                      {getOver(bowler.stats.balling_balls)}
                    </span>
                  </div>
                  <div className="match-inning-score-header-item w-10">
                    <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                      {bowler.stats.balling_score}
                    </span>
                  </div>
                  <div className="match-inning-score-header-item w-10">
                    <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                      {bowler.stats.out}
                    </span>
                  </div>
                  <div className="match-inning-score-header-item w-10">
                    <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
                      {(
                        bowler.stats.balling_score /
                        getOver(bowler.stats.balling_balls)
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default MatchInningDetails;
