const INITIAL_STATE = {
  payment: [],
  myTrials: [],
  allTrials: [],
  trialDetails: {},
  staticContent: "",
  loading: {
    trialDetails: true,
    myTrials: true,
    allTrials: true,
    payment: true,
    staticContent: true,
  },
};

export default INITIAL_STATE;
