import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabBar, MobNavbar } from "../../shared";
import Skeleton from "./card-skeleton";
import "./styles.css";

import { getAllUpdates } from "../../actions";

class Notidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUpdates: [],
      allUpdatesLoading: true,
    };
  }

  componentDidMount = () => {
    this.props.getAllUpdates();
  };

  componentDidUpdate = (prevProps) => {
    const { allUpdates, loading } = this.props;
    if (prevProps.allUpdates !== allUpdates) {
      this.setState({
        allUpdates: allUpdates,
        allUpdatesLoading: loading.allUpdates,
      });
    }
  };

  renderUpdates = () => {
    const { allUpdates } = this.state;
    if (!allUpdates.length) {
      return (
        <div
          className="text-center"
          style={{ height: "300px", margin: "100px 0px" }}
        >
          <strong className="pc">No Updates Yet!</strong>
        </div>
      );
    }
    return allUpdates.map((item, index) => {
      return (
        <div
          className="box mob-notification-item m-3 p-4"
          key={index.toString()}
        >
          <article className="media">
            <div className="media-content">
              <div className="content">
                <p className="pc is-size-7 has-text-weight-semibold mt-1">
                  {item.message}
                </p>
                <small className="ml-1 has-text-grey has-text-weight-medium is-size-7">
                  {new Date(item.updated_at).toLocaleDateString()}
                </small>
              </div>
            </div>
          </article>
        </div>
      );
    });
  };

  render() {
    const { allUpdatesLoading } = this.state;
    return (
      <>
        <MobNavbar
          title="Updates"
          subHeading="Get notified on every little update from us"
        />
        <div className="container mob-notification-container">
          <div
            className="content"
            style={{ minHeight: "70vh", marginTop: "30px" }}
          >
            {allUpdatesLoading ? <Skeleton /> : this.renderUpdates()}
          </div>
        </div>
        <TabBar />
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    allUpdates: user.allUpdates,
    loading: user.loading,
  };
};

export default connect(mapStateToProps, {
  getAllUpdates,
})(withRouter(Notidates));
