import axios from "axios";

import { base_url } from "../config";

import { error_handler } from "../handlers/errorHandler";
import { getCookie, deleteCookie } from "../handlers/cookieHandler";

import {
  CHECK_EMAIL,
  LOGIN,
  LOGOUT,
  SIGNUP,
  ONBOARDING,
  RESET_USER_DETAILS,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
} from "../actionTypes";

const tokenChange = (token_value = "") => {
  try {
    if (token_value && token_value !== undefined) {
      localStorage.setItem("auth_token", token_value);
    }
  } catch (error) {
    // Check Error
  }
};

function clearAll() {
  localStorage.clear();
}

// Check User with Email or Mobile Exists or not
export const checkUser = ({ email, mobile }) => {
  let config = {};
  let data = {
    email,
    mobile,
  };

  return (dispatch) => {
    axios
      .post(base_url + "user_exists", data, config)
      .then(function (response) {
        localStorage.setItem(
          "onboarding",
          JSON.stringify(response.data.data.onboarding)
        );
        if (response.status === 200) {
          dispatch({
            type: CHECK_EMAIL,
            payload: { email, mobile, ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: CHECK_EMAIL,
          payload: {},
        });
      });
  };
};

export const loginCall = ({ email, mobile, password }) => {
  let config = {};
  let data = {
    email,
    mobile,
    password,
  };

  return (dispatch) => {
    axios
      .post(base_url + "login", data, config)
      .then(function (response) {
        if (response.status === 200) {
          try {
            deleteCookie("referral");
            window.success("Login Successful");
          } catch (error) {}
          const { access_token } = response.data.data;
          tokenChange(access_token);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("mobile", response.data.data.mobile);
          dispatch({
            type: LOGIN,
            payload: { email, mobile, ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        window.error("Incorrect Password");
        dispatch({
          type: LOGIN,
          payload: {},
        });
      });
  };
};

export const logout = () => {
  let config = {
    headers: { "X-Auth-Token": localStorage.getItem("auth_token") },
  };
  return (dispatch) => {
    axios
      .post(base_url + "logout", config)
      .then(function (response) {})
      .catch(function (error) {
        error_handler(error);
      })
      .finally(function () {
        clearAll();
        dispatch({
          type: LOGOUT,
          payload: {},
        });
        window.location = "/auth";
      });
  };
};

export const signupCall = ({ email, mobile, password, otp, signup_with }) => {
  let config = {};
  let data = {
    email,
    mobile,
    password,
    otp,
    signup_with,
  };

  const referral = getCookie("referral");
  if (referral) data["referral"] = referral;

  return (dispatch) => {
    axios
      .post(base_url + "signup", data, config)
      .then(function (response) {
        if (response.status === 200 && response.data.success) {
          try {
            deleteCookie("referral");
            window.success("Signup Successful");
          } catch (error) {}
          const { access_token } = response.data.data;
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("mobile", response.data.data.mobile);
          tokenChange(access_token);
          dispatch({
            type: SIGNUP,
            payload: { email, mobile, ...response.data.data },
          });
        }
        if (response.status === 200 && !response.data.success) {
          window.error(response.data.message);
          const { access_token } = response.data.data;
          tokenChange(access_token);
          dispatch({
            type: SIGNUP,
            payload: { email, mobile, ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        window.error("Invalid OTP");
        error_handler(error);
        dispatch({
          type: SIGNUP,
          payload: {},
        });
      });
  };
};

export const signupCompleteCall = ({
  email,
  mobile,
  password,
  otp,
  signup_with,
}) => {
  let config = {};
  let data = {
    email,
    mobile,
    password,
    otp,
    signup_with,
  };

  return (dispatch) => {
    axios
      .post(base_url + "complete_signup", data, config)
      .then(function (response) {
        if (response.status === 200 && response.data.success) {
          window.success("Signup Successful");
          const { access_token } = response.data.data;
          tokenChange(access_token);
          dispatch({
            type: SIGNUP,
            payload: { email, mobile, ...response.data.data },
          });
        }
        if (response.status === 200 && !response.data.success) {
          window.error(response.data.message);
          const { access_token } = response.data.data;
          tokenChange(access_token);
          dispatch({
            type: SIGNUP,
            payload: { email, mobile, ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: SIGNUP,
          payload: {},
        });
      });
  };
};

export const onboardingCall = (obj) => {
  const {
    name,
    age,
    speciality,
    state,
    city,
    gender,
    sport,
    pincode,
    profession,
  } = obj;
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  let data = {
    name,
    age,
    pincode,
    speciality,
    profession,
    gender,
    sport,
    state,
    city,
  };

  return (dispatch) => {
    axios
      .post(base_url + "onboarding", data, config)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("onboarding", "true");
          dispatch({
            type: ONBOARDING,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: ONBOARDING,
          payload: {},
        });
      });
  };
};

export const forgotPasswordCall = ({ email = "", mobile = "" }) => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  let data = {
    email,
    mobile,
  };

  return (dispatch) => {
    axios
      .post(base_url + "forgot", data, config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: FORGOT_PASSWORD,
            payload: { otpSent: true },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: FORGOT_PASSWORD,
          payload: {
            otpSent: false,
            message: "Something went wrong, Try again Later",
          },
        });
      });
  };
};

export const changePasswordCall = ({
  email = "",
  mobile = "",
  otp,
  password,
}) => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  let data = {
    email,
    mobile,
    otp,
    password,
  };

  return (dispatch) => {
    axios
      .post(base_url + "change_password", data, config)
      .then(function (response) {
        if (response.status === 200 && response.data.success) {
          dispatch({
            type: CHANGE_PASSWORD,
            payload: { passwordUpdated: true, message: response.data.message },
          });
        }
        if (response.status === 200 && !response.data.success) {
          dispatch({
            type: CHANGE_PASSWORD,
            payload: { passwordUpdated: false, message: response.data.message },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: CHANGE_PASSWORD,
          payload: { message: "Something went wrong, Try again Later" },
        });
      });
  };
};

export const resetOTPMessage = () => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: { message: "" },
    });
  };
};

export const resetUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER_DETAILS,
      payload: "",
    });
  };
};
