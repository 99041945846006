import INITIAL_STATE from "./models/dashboard";
import {
    GET_DASHBOARD,
} from "../actionTypes";

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case GET_DASHBOARD:
			if(action.payload.details) {
				let data = action.payload.details;
				return {
					activeTournaments: [...data.tournaments],
					activeTrials: [...data.trials],
					user: {...data.user},
					dashboardLoading: false
				};
			}
      return {...state,	dashboardLoading: false};
    default:
      return state;
  }
};
