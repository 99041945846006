import INITIAL_STATE from "./models/tournament";
import {
  ALL_TOURNAMENTS,
  TOURNAMENT_TEAMS,
  TOURNAMENT_DETAILS,
  GET_SELECTION_STATIC_CONTENT,
  GET_TOURNAMENT_STATIC_CONTENT,
} from "../actionTypes";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_TOURNAMENTS:
      return {
        ...state,
        allTournaments: [...action.payload.tournaments],
        loading: { ...state.loading, allTournaments: false },
      };

    case TOURNAMENT_TEAMS:
      return {
        ...state,
        tournamentDetails: {
          ...state.tournamentDetails,
          allTeams: [...action.payload.teams],
        },
        loading: { ...state.loading, allTeams: false },
      };

    case TOURNAMENT_DETAILS:
      console.log("action.payload", action.payload);
      return {
        ...state,
        tournamentDetails: {
          ...state.tournamentDetails,
          selectionTournaments: [...action.payload.selection_tournaments],
        },
        loading: { ...state.loading, selectionTournaments: false },
      };

    case GET_SELECTION_STATIC_CONTENT:
      return {
        ...state,
        tournamentDetails: {
          ...state.tournamentDetails,
          selectionContent: action.payload.static_content,
        },
        loading: { ...state.loading, selectionContent: false },
      };

    case GET_TOURNAMENT_STATIC_CONTENT:
      return {
        ...state,
        tournamentDetails: {
          ...state.tournamentDetails,
          tournamentContent: action.payload.static_content,
        },
        loading: { ...state.loading, tournamentContent: false },
      };
    default:
      return state;
  }
};
