import axios from "axios";
import { base_url } from "../config";
import { error_handler } from "../handlers/errorHandler";
import {
  ALL_TOURNAMENTS,
  TOURNAMENT_TEAMS,
  TOURNAMENT_DETAILS,
  GET_SELECTION_STATIC_CONTENT,
  GET_TOURNAMENT_STATIC_CONTENT,
} from "../actionTypes";

// get all Tournaments
export const getAllTournaments = () => {
  return (dispatch) => {
    axios
      .get(
        base_url + "tournaments"
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: ALL_TOURNAMENTS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: ALL_TOURNAMENTS,
          payload: {},
        });
      });
  };
};

//get Tournament Details
export const getTournamentDetails = (id) => {
  console.log("======>");
  return (dispatch) => {
    axios
      .get(
        base_url + "tournament/" + id
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: TOURNAMENT_DETAILS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: TOURNAMENT_DETAILS,
          payload: {},
        });
      });
  };
};

// Get All Teams
export const getTournamentTeams = (tournamentId) => {
  return (dispatch) => {
    axios
      .get(
        base_url + "tournament/" + tournamentId + "/teams"
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: TOURNAMENT_TEAMS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: TOURNAMENT_TEAMS,
          payload: {},
        });
      });
  };
};

// check Tournament Content
export const getTournamentStaticContent = (tournamentId) => {
  return (dispatch) => {
    axios
      .get(
        base_url + "tournament_details/" + tournamentId
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response", response);
          dispatch({
            type: GET_TOURNAMENT_STATIC_CONTENT,
            payload: { static_content: response.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_TOURNAMENT_STATIC_CONTENT,
          payload: {},
        });
      });
  };
};

// check Selection Content
export const getSelectionStaticContent = (tournamentId) => {
  return (dispatch) => {
    axios
      .get(
        base_url + "selection_match_details/" + tournamentId
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response", response);
          dispatch({
            type: GET_SELECTION_STATIC_CONTENT,
            payload: { static_content: response.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_SELECTION_STATIC_CONTENT,
          payload: {},
        });
      });
  };
};
