import axios from "axios";
import { base_url } from '../config';
import { error_handler } from "../handlers/errorHandler";
import {
  TOURNAMENT_MATCHES,
  MATCH_DETAILS
} from "../actionTypes";

// check Tournament Matches
export const getMatches = (tournamentId) => {
  return (dispatch) => {
    axios.get(
      base_url + 'tournament/' + tournamentId + '/matches'
      // config
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: TOURNAMENT_MATCHES,
          payload: { ...response.data.data }
        });
      }
    }).catch(function (error) {
      error_handler(error);
      dispatch({
        type: TOURNAMENT_MATCHES,
        payload: {}
      });
    });
  }
}


// check Match Details
export const getMatchDetails = (id) => {
  return (dispatch) => {
    axios.get(
      base_url + 'match/' + id
      // config
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: MATCH_DETAILS,
          payload: { ...response.data.data }
        });
      }
    }).catch(function (error) {
      error_handler(error);
      dispatch({
        type: MATCH_DETAILS,
        payload: {}
      });
    });
  }
}
