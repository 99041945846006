export const cities =[
    {
      "city_name": "Mumbai",
      "city_id": 15
    },
    {
      "city_name": "Lucknow",
      "city_id": 22
    },
    {
      "city_name": "Hyderabad",
      "city_id": 20
    },
    {
      "city_name": "Ahmedabad",
      "city_id": 7
    },
    {
      "city_name": "Delhi",
      "city_id": 6
    },
    {
      "city_name": "Noida",
      "city_id": 22
    },
    {
      "city_name": "Vijaywada",
      "city_id": 1
    },
    {
      "city_name": "Patna",
      "city_id": 3
    },
    {
      "city_name": "Gurgaon",
      "city_id": 8
    },
    {
      "city_name": "Chennai",
      "city_id": 19
    },
    {
      "city_name": "Kolkata",
      "city_id": 23
    },
    {
      "city_name": "Ranchi",
      "city_id": 11
    },
    {
      "city_name": "Chandigarh",
      "city_id": 4
    },
    {
      "city_name": "Bangalore",
      "city_id": 12
    },
    {
      "city_name": "Jaipur",
      "city_id": 18
    },
    {
      "city_name": "Indore",
      "city_id": 14
    },
    {
      "city_name": "Ludhiana",
      "city_id": 17
    },
    {
      "city_name": "Guwahati",
      "city_id": 2
    },
    {
      "city_name": "Kota",
      "city_id": 18
    },
    {
      "city_name": "Jammu",
      "city_id": 10
    },
    {
      "city_name": "Ambala",
      "city_id": 8
    },
    {
      "city_name": "Kochi",
      "city_id": 13
    },
    {
      "city_name": "Adilabad",
      "city_id": 20
    },
    {
      "city_name": "Agra",
      "city_id": 22
    },
    {
      "city_name": "Ahmed Nagar",
      "city_id": 15
    },
    {
      "city_name": "Ajmer",
      "city_id": 18
    },
    {
      "city_name": "Akola",
      "city_id": 15
    },
    {
      "city_name": "Aligarh",
      "city_id": 22
    },
    {
      "city_name": "Allahabad",
      "city_id": 22
    },
    {
      "city_name": "Alleppey",
      "city_id": 13
    },
    {
      "city_name": "Almora",
      "city_id": 21
    },
    {
      "city_name": "Alwar",
      "city_id": 18
    },
    {
      "city_name": "Alwaye",
      "city_id": 13
    },
    {
      "city_name": "Amalapuram",
      "city_id": 1
    },
    {
      "city_name": "Ambedkar Nagar",
      "city_id": 22
    },
    {
      "city_name": "Amravati",
      "city_id": 15
    },
    {
      "city_name": "Amreli",
      "city_id": 7
    },
    {
      "city_name": "Amritsar",
      "city_id": 17
    },
    {
      "city_name": "Anakapalle",
      "city_id": 1
    },
    {
      "city_name": "Anand",
      "city_id": 7
    },
    {
      "city_name": "Anantapur",
      "city_id": 1
    },
    {
      "city_name": "Ananthnag",
      "city_id": 10
    },
    {
      "city_name": "Anna Road H.O",
      "city_id": 19
    },
    {
      "city_name": "Arakkonam",
      "city_id": 19
    },
    {
      "city_name": "Asansol",
      "city_id": 23
    },
    {
      "city_name": "Aska",
      "city_id": 16
    },
    {
      "city_name": "Auraiya",
      "city_id": 22
    },
    {
      "city_name": "Aurangabad",
      "city_id": 15
    },
    {
      "city_name": "Aurangabad",
      "city_id": 3
    },
    {
      "city_name": "Azamgarh",
      "city_id": 22
    },
    {
      "city_name": "Bagalkot",
      "city_id": 12
    },
    {
      "city_name": "Bageshwar",
      "city_id": 21
    },
    {
      "city_name": "Bagpat",
      "city_id": 22
    },
    {
      "city_name": "Bahraich",
      "city_id": 22
    },
    {
      "city_name": "Balaghat",
      "city_id": 14
    },
    {
      "city_name": "Balangir",
      "city_id": 16
    },
    {
      "city_name": "Balasore",
      "city_id": 16
    },
    {
      "city_name": "Ballia",
      "city_id": 22
    },
    {
      "city_name": "Balrampur",
      "city_id": 22
    },
    {
      "city_name": "Banasanktha",
      "city_id": 7
    },
    {
      "city_name": "Banda",
      "city_id": 22
    },
    {
      "city_name": "Bandipur",
      "city_id": 10
    },
    {
      "city_name": "Bankura",
      "city_id": 23
    },
    {
      "city_name": "Banswara",
      "city_id": 18
    },
    {
      "city_name": "Barabanki",
      "city_id": 22
    },
    {
      "city_name": "Baramulla",
      "city_id": 10
    },
    {
      "city_name": "Baran",
      "city_id": 18
    },
    {
      "city_name": "Bardoli",
      "city_id": 7
    },
    {
      "city_name": "Bareilly",
      "city_id": 22
    },
    {
      "city_name": "Barmer",
      "city_id": 18
    },
    {
      "city_name": "Barnala",
      "city_id": 17
    },
    {
      "city_name": "Barpeta",
      "city_id": 2
    },
    {
      "city_name": "Bastar",
      "city_id": 5
    },
    {
      "city_name": "Basti",
      "city_id": 22
    },
    {
      "city_name": "Bathinda",
      "city_id": 17
    },
    {
      "city_name": "Beed",
      "city_id": 15
    },
    {
      "city_name": "Begusarai",
      "city_id": 3
    },
    {
      "city_name": "Belgaum",
      "city_id": 12
    },
    {
      "city_name": "Bellary",
      "city_id": 12
    },
    {
      "city_name": "Bengaluru East",
      "city_id": 12
    },
    {
      "city_name": "Bengaluru South",
      "city_id": 12
    },
    {
      "city_name": "Bengaluru West",
      "city_id": 12
    },
    {
      "city_name": "Berhampur",
      "city_id": 16
    },
    {
      "city_name": "Bhadrak",
      "city_id": 16
    },
    {
      "city_name": "Bhagalpur",
      "city_id": 3
    },
    {
      "city_name": "Bhandara",
      "city_id": 15
    },
    {
      "city_name": "Bharatpur",
      "city_id": 18
    },
    {
      "city_name": "Bharuch",
      "city_id": 7
    },
    {
      "city_name": "Bhavnagar",
      "city_id": 7
    },
    {
      "city_name": "Bhilwara",
      "city_id": 18
    },
    {
      "city_name": "Bhimavaram",
      "city_id": 1
    },
    {
      "city_name": "Bhiwani",
      "city_id": 8
    },
    {
      "city_name": "Bhojpur",
      "city_id": 3
    },
    {
      "city_name": "Bhopal",
      "city_id": 14
    },
    {
      "city_name": "Bhubaneswar",
      "city_id": 16
    },
    {
      "city_name": "Bidar",
      "city_id": 12
    },
    {
      "city_name": "Bijapur",
      "city_id": 12
    },
    {
      "city_name": "Bijnor",
      "city_id": 22
    },
    {
      "city_name": "Bikaner",
      "city_id": 18
    },
    {
      "city_name": "Bilaspur",
      "city_id": 5
    },
    {
      "city_name": "Birbhum",
      "city_id": 23
    },
    {
      "city_name": "Bongaigaon",
      "city_id": 2
    },
    {
      "city_name": "Budaun",
      "city_id": 22
    },
    {
      "city_name": "Budgam",
      "city_id": 10
    },
    {
      "city_name": "Bulandshahr",
      "city_id": 22
    },
    {
      "city_name": "Buldhana",
      "city_id": 15
    },
    {
      "city_name": "Bundi",
      "city_id": 18
    },
    {
      "city_name": "Burdwan",
      "city_id": 23
    },
    {
      "city_name": "Cachar",
      "city_id": 2
    },
    {
      "city_name": "Calicut",
      "city_id": 13
    },
    {
      "city_name": "Chamba",
      "city_id": 9
    },
    {
      "city_name": "Chamoli",
      "city_id": 21
    },
    {
      "city_name": "Champawat",
      "city_id": 21
    },
    {
      "city_name": "Chandauli",
      "city_id": 22
    },
    {
      "city_name": "Chandrapur",
      "city_id": 15
    },
    {
      "city_name": "Changanacherry",
      "city_id": 13
    },
    {
      "city_name": "Channapatna",
      "city_id": 12
    },
    {
      "city_name": "Chengalpattu",
      "city_id": 19
    },
    {
      "city_name": "Chennai City Central",
      "city_id": 19
    },
    {
      "city_name": "Chennai City North",
      "city_id": 19
    },
    {
      "city_name": "Chennai City South",
      "city_id": 19
    },
    {
      "city_name": "Chhatarpur",
      "city_id": 14
    },
    {
      "city_name": "Chhindwara",
      "city_id": 14
    },
    {
      "city_name": "Chikmagalur",
      "city_id": 12
    },
    {
      "city_name": "Chikodi",
      "city_id": 12
    },
    {
      "city_name": "Chitradurga",
      "city_id": 12
    },
    {
      "city_name": "Chitrakoot",
      "city_id": 22
    },
    {
      "city_name": "Chittoor",
      "city_id": 1
    },
    {
      "city_name": "Chittorgarh",
      "city_id": 18
    },
    {
      "city_name": "Churu",
      "city_id": 18
    },
    {
      "city_name": "Coimbatore",
      "city_id": 19
    },
    {
      "city_name": "Contai",
      "city_id": 23
    },
    {
      "city_name": "Cooch Behar",
      "city_id": 23
    },
    {
      "city_name": "Cuddalore",
      "city_id": 19
    },
    {
      "city_name": "Cuddapah",
      "city_id": 1
    },
    {
      "city_name": "Cuttack City",
      "city_id": 16
    },
    {
      "city_name": "Cuttack North",
      "city_id": 16
    },
    {
      "city_name": "Cuttack South",
      "city_id": 16
    },
    {
      "city_name": "Darbhanga",
      "city_id": 3
    },
    {
      "city_name": "Darjiling",
      "city_id": 23
    },
    {
      "city_name": "Darrang",
      "city_id": 2
    },
    {
      "city_name": "Dausa",
      "city_id": 18
    },
    {
      "city_name": "Dehra Gopipur",
      "city_id": 9
    },
    {
      "city_name": "Dehradun",
      "city_id": 21
    },
    {
      "city_name": "Deoria",
      "city_id": 22
    },
    {
      "city_name": "Dhanbad",
      "city_id": 11
    },
    {
      "city_name": "Dharamsala",
      "city_id": 9
    },
    {
      "city_name": "Dharmapuri",
      "city_id": 19
    },
    {
      "city_name": "Dharwad",
      "city_id": 12
    },
    {
      "city_name": "Dhemaji",
      "city_id": 2
    },
    {
      "city_name": "Dhenkanal",
      "city_id": 16
    },
    {
      "city_name": "Dholpur",
      "city_id": 18
    },
    {
      "city_name": "Dhubri",
      "city_id": 2
    },
    {
      "city_name": "Dhule",
      "city_id": 15
    },
    {
      "city_name": "Dibrugarh",
      "city_id": 2
    },
    {
      "city_name": "Dindigul",
      "city_id": 19
    },
    {
      "city_name": "Doda",
      "city_id": 10
    },
    {
      "city_name": "Dungarpur",
      "city_id": 18
    },
    {
      "city_name": "Durg",
      "city_id": 5
    },
    {
      "city_name": "East Champaran",
      "city_id": 3
    },
    {
      "city_name": "Eluru",
      "city_id": 1
    },
    {
      "city_name": "Ernakulam",
      "city_id": 13
    },
    {
      "city_name": "Erode",
      "city_id": 19
    },
    {
      "city_name": "Etah",
      "city_id": 22
    },
    {
      "city_name": "Etawah",
      "city_id": 22
    },
    {
      "city_name": "Faizabad",
      "city_id": 22
    },
    {
      "city_name": "Faridabad",
      "city_id": 8
    },
    {
      "city_name": "Faridkot",
      "city_id": 17
    },
    {
      "city_name": "Farrukhabad",
      "city_id": 22
    },
    {
      "city_name": "Fatehgarh Sahib",
      "city_id": 17
    },
    {
      "city_name": "Fatehpur",
      "city_id": 22
    },
    {
      "city_name": "Fazilka",
      "city_id": 17
    },
    {
      "city_name": "Firozabad",
      "city_id": 22
    },
    {
      "city_name": "Firozpur",
      "city_id": 17
    },
    {
      "city_name": "Gadag",
      "city_id": 12
    },
    {
      "city_name": "Gadchiroli",
      "city_id": 15
    },
    {
      "city_name": "Gandhinagar",
      "city_id": 7
    },
    {
      "city_name": "Ganganagar",
      "city_id": 18
    },
    {
      "city_name": "Gautam Buddha Nagar",
      "city_id": 22
    },
    {
      "city_name": "Gaya",
      "city_id": 3
    },
    {
      "city_name": "Ghaziabad",
      "city_id": 22
    },
    {
      "city_name": "Ghazipur",
      "city_id": 22
    },
    {
      "city_name": "Giridih",
      "city_id": 11
    },
    {
      "city_name": "Goalpara",
      "city_id": 2
    },
    {
      "city_name": "Gokak",
      "city_id": 12
    },
    {
      "city_name": "Golaghat",
      "city_id": 2
    },
    {
      "city_name": "Gonda",
      "city_id": 22
    },
    {
      "city_name": "Gondal",
      "city_id": 7
    },
    {
      "city_name": "Gondia",
      "city_id": 15
    },
    {
      "city_name": "Gorakhpur",
      "city_id": 22
    },
    {
      "city_name": "Gudivada",
      "city_id": 1
    },
    {
      "city_name": "Gudur",
      "city_id": 1
    },
    {
      "city_name": "Gulbarga",
      "city_id": 12
    },
    {
      "city_name": "Guna",
      "city_id": 14
    },
    {
      "city_name": "Guntur",
      "city_id": 1
    },
    {
      "city_name": "Gurdaspur",
      "city_id": 17
    },
    {
      "city_name": "Gwalior",
      "city_id": 14
    },
    {
      "city_name": "Hailakandi",
      "city_id": 2
    },
    {
      "city_name": "Hamirpur",
      "city_id": 9
    },
    {
      "city_name": "Hamirpur",
      "city_id": 22
    },
    {
      "city_name": "Hanamkonda",
      "city_id": 20
    },
    {
      "city_name": "Hanumangarh",
      "city_id": 18
    },
    {
      "city_name": "Hardoi",
      "city_id": 22
    },
    {
      "city_name": "Haridwar",
      "city_id": 21
    },
    {
      "city_name": "Hassan",
      "city_id": 12
    },
    {
      "city_name": "Hathras",
      "city_id": 22
    },
    {
      "city_name": "Haveri",
      "city_id": 12
    },
    {
      "city_name": "Hazaribagh",
      "city_id": 11
    },
    {
      "city_name": "Hindupur",
      "city_id": 1
    },
    {
      "city_name": "Hingoli",
      "city_id": 15
    },
    {
      "city_name": "Hissar",
      "city_id": 8
    },
    {
      "city_name": "Hooghly",
      "city_id": 23
    },
    {
      "city_name": "Hoshangabad",
      "city_id": 14
    },
    {
      "city_name": "Hoshiarpur",
      "city_id": 17
    },
    {
      "city_name": "Howrah",
      "city_id": 23
    },
    {
      "city_name": "Hyderabad South East",
      "city_id": 20
    },
    {
      "city_name": "Idukki",
      "city_id": 13
    },
    {
      "city_name": "Indore Moffusil",
      "city_id": 14
    },
    {
      "city_name": "Irinjalakuda",
      "city_id": 13
    },
    {
      "city_name": "Jabalpur",
      "city_id": 14
    },
    {
      "city_name": "Jaisalmer",
      "city_id": 18
    },
    {
      "city_name": "Jalandhar",
      "city_id": 17
    },
    {
      "city_name": "Jalaun",
      "city_id": 22
    },
    {
      "city_name": "Jalgaon",
      "city_id": 15
    },
    {
      "city_name": "Jalna",
      "city_id": 15
    },
    {
      "city_name": "Jalor",
      "city_id": 18
    },
    {
      "city_name": "Jalpaiguri",
      "city_id": 23
    },
    {
      "city_name": "Jamnagar",
      "city_id": 7
    },
    {
      "city_name": "Jaunpur",
      "city_id": 22
    },
    {
      "city_name": "Jhalawar",
      "city_id": 18
    },
    {
      "city_name": "Jhansi",
      "city_id": 22
    },
    {
      "city_name": "Jhujhunu",
      "city_id": 18
    },
    {
      "city_name": "Jodhpur",
      "city_id": 18
    },
    {
      "city_name": "Jorhat",
      "city_id": 2
    },
    {
      "city_name": "Junagadh",
      "city_id": 7
    },
    {
      "city_name": "Jyotiba Phule Nagar",
      "city_id": 22
    },
    {
      "city_name": "Kakinada",
      "city_id": 1
    },
    {
      "city_name": "Kalahandi",
      "city_id": 16
    },
    {
      "city_name": "Kamrup",
      "city_id": 2
    },
    {
      "city_name": "Kanchipuram",
      "city_id": 19
    },
    {
      "city_name": "Kannauj",
      "city_id": 22
    },
    {
      "city_name": "Kanniyakumari",
      "city_id": 19
    },
    {
      "city_name": "Kannur",
      "city_id": 13
    },
    {
      "city_name": "Kanpur Dehat",
      "city_id": 22
    },
    {
      "city_name": "Kanpur Nagar",
      "city_id": 22
    },
    {
      "city_name": "Kapurthala",
      "city_id": 17
    },
    {
      "city_name": "Karaikudi",
      "city_id": 19
    },
    {
      "city_name": "Karauli",
      "city_id": 18
    },
    {
      "city_name": "Karbi Anglong",
      "city_id": 2
    },
    {
      "city_name": "Kargil",
      "city_id": 10
    },
    {
      "city_name": "Karimganj",
      "city_id": 2
    },
    {
      "city_name": "Karimnagar",
      "city_id": 20
    },
    {
      "city_name": "Karnal",
      "city_id": 8
    },
    {
      "city_name": "Karur",
      "city_id": 19
    },
    {
      "city_name": "Karwar",
      "city_id": 12
    },
    {
      "city_name": "Kasaragod",
      "city_id": 13
    },
    {
      "city_name": "Kathua",
      "city_id": 10
    },
    {
      "city_name": "Kaushambi",
      "city_id": 22
    },
    {
      "city_name": "Keonjhar",
      "city_id": 16
    },
    {
      "city_name": "Khammam",
      "city_id": 1
    },
    {
      "city_name": "Khammam",
      "city_id": 20
    },
    {
      "city_name": "Khandwa",
      "city_id": 14
    },
    {
      "city_name": "Kheda",
      "city_id": 7
    },
    {
      "city_name": "Kheri",
      "city_id": 22
    },
    {
      "city_name": "Kodagu",
      "city_id": 12
    },
    {
      "city_name": "Kokrajhar",
      "city_id": 2
    },
    {
      "city_name": "Kolar",
      "city_id": 12
    },
    {
      "city_name": "Kolhapur",
      "city_id": 15
    },
    {
      "city_name": "Koraput",
      "city_id": 16
    },
    {
      "city_name": "Kottayam",
      "city_id": 13
    },
    {
      "city_name": "Kovilpatti",
      "city_id": 19
    },
    {
      "city_name": "Krishnagiri",
      "city_id": 19
    },
    {
      "city_name": "Kulgam",
      "city_id": 10
    },
    {
      "city_name": "Kumbakonam",
      "city_id": 19
    },
    {
      "city_name": "Kupwara",
      "city_id": 10
    },
    {
      "city_name": "Kurnool",
      "city_id": 1
    },
    {
      "city_name": "Kurukshetra",
      "city_id": 8
    },
    {
      "city_name": "Kushinagar",
      "city_id": 22
    },
    {
      "city_name": "Kutch",
      "city_id": 7
    },
    {
      "city_name": "Lakhimpur",
      "city_id": 2
    },
    {
      "city_name": "Lalitpur",
      "city_id": 22
    },
    {
      "city_name": "Latur",
      "city_id": 15
    },
    {
      "city_name": "Leh",
      "city_id": 10
    },
    {
      "city_name": "Machilipatnam",
      "city_id": 1
    },
    {
      "city_name": "Madhubani",
      "city_id": 3
    },
    {
      "city_name": "Madurai",
      "city_id": 19
    },
    {
      "city_name": "Mahabubnagar",
      "city_id": 20
    },
    {
      "city_name": "Maharajganj",
      "city_id": 22
    },
    {
      "city_name": "Mahesana",
      "city_id": 7
    },
    {
      "city_name": "Mahoba",
      "city_id": 22
    },
    {
      "city_name": "Mainpuri",
      "city_id": 22
    },
    {
      "city_name": "Malda",
      "city_id": 23
    },
    {
      "city_name": "Mandi",
      "city_id": 9
    },
    {
      "city_name": "Mandsaur",
      "city_id": 14
    },
    {
      "city_name": "Mandya",
      "city_id": 12
    },
    {
      "city_name": "Mangalore",
      "city_id": 12
    },
    {
      "city_name": "Manjeri",
      "city_id": 13
    },
    {
      "city_name": "Mansa",
      "city_id": 17
    },
    {
      "city_name": "Marigaon",
      "city_id": 2
    },
    {
      "city_name": "Mathura",
      "city_id": 22
    },
    {
      "city_name": "Mau",
      "city_id": 22
    },
    {
      "city_name": "Mavelikara",
      "city_id": 13
    },
    {
      "city_name": "Mayiladuthurai",
      "city_id": 19
    },
    {
      "city_name": "Mayurbhanj",
      "city_id": 16
    },
    {
      "city_name": "Medak",
      "city_id": 20
    },
    {
      "city_name": "Meerut",
      "city_id": 22
    },
    {
      "city_name": "Midnapore",
      "city_id": 23
    },
    {
      "city_name": "Mirzapur",
      "city_id": 22
    },
    {
      "city_name": "Moga",
      "city_id": 17
    },
    {
      "city_name": "Mohali",
      "city_id": 17
    },
    {
      "city_name": "Monghyr",
      "city_id": 3
    },
    {
      "city_name": "Moradabad",
      "city_id": 22
    },
    {
      "city_name": "Morena",
      "city_id": 14
    },
    {
      "city_name": "Muktsar",
      "city_id": 17
    },
    {
      "city_name": "Murshidabad",
      "city_id": 23
    },
    {
      "city_name": "Muzaffarnagar",
      "city_id": 22
    },
    {
      "city_name": "Muzaffarpur",
      "city_id": 3
    },
    {
      "city_name": "Mysore",
      "city_id": 12
    },
    {
      "city_name": "Nadia",
      "city_id": 23
    },
    {
      "city_name": "Nagaon",
      "city_id": 2
    },
    {
      "city_name": "Nagapattinam",
      "city_id": 19
    },
    {
      "city_name": "Nagaur",
      "city_id": 18
    },
    {
      "city_name": "Nagpur",
      "city_id": 15
    },
    {
      "city_name": "Nainital",
      "city_id": 21
    },
    {
      "city_name": "Nalanda",
      "city_id": 3
    },
    {
      "city_name": "Nalbari",
      "city_id": 2
    },
    {
      "city_name": "Nalgonda",
      "city_id": 20
    },
    {
      "city_name": "Namakkal",
      "city_id": 19
    },
    {
      "city_name": "Nanded",
      "city_id": 15
    },
    {
      "city_name": "Nandurbar",
      "city_id": 15
    },
    {
      "city_name": "Nandyal",
      "city_id": 1
    },
    {
      "city_name": "Nanjangud",
      "city_id": 12
    },
    {
      "city_name": "Narasaraopet",
      "city_id": 1
    },
    {
      "city_name": "Nashik",
      "city_id": 15
    },
    {
      "city_name": "Navsari",
      "city_id": 7
    },
    {
      "city_name": "Nawadha",
      "city_id": 3
    },
    {
      "city_name": "Nawanshahr",
      "city_id": 17
    },
    {
      "city_name": "Nellore",
      "city_id": 1
    },
    {
      "city_name": "Nilgiris",
      "city_id": 19
    },
    {
      "city_name": "Nizamabad",
      "city_id": 20
    },
    {
      "city_name": "North 24 Parganas",
      "city_id": 23
    },
    {
      "city_name": "North Cachar Hills",
      "city_id": 2
    },
    {
      "city_name": "North Dinajpur",
      "city_id": 23
    },
    {
      "city_name": "Osmanabad",
      "city_id": 15
    },
    {
      "city_name": "Ottapalam",
      "city_id": 13
    },
    {
      "city_name": "Palamau",
      "city_id": 11
    },
    {
      "city_name": "Palghat",
      "city_id": 13
    },
    {
      "city_name": "Pali",
      "city_id": 18
    },
    {
      "city_name": "Panchmahals",
      "city_id": 7
    },
    {
      "city_name": "Parbhani",
      "city_id": 15
    },
    {
      "city_name": "Parvathipuram",
      "city_id": 1
    },
    {
      "city_name": "Patan",
      "city_id": 7
    },
    {
      "city_name": "Pathanamthitta",
      "city_id": 13
    },
    {
      "city_name": "Patiala",
      "city_id": 17
    },
    {
      "city_name": "Pattukottai",
      "city_id": 19
    },
    {
      "city_name": "Pauri Garhwal",
      "city_id": 21
    },
    {
      "city_name": "Peddapalli",
      "city_id": 20
    },
    {
      "city_name": "Phulbani",
      "city_id": 16
    },
    {
      "city_name": "Pilibhit",
      "city_id": 22
    },
    {
      "city_name": "Pithoragarh",
      "city_id": 21
    },
    {
      "city_name": "Poducherry",
      "city_id": 19
    },
    {
      "city_name": "Pollachi",
      "city_id": 19
    },
    {
      "city_name": "Poonch",
      "city_id": 10
    },
    {
      "city_name": "Porbandar",
      "city_id": 7
    },
    {
      "city_name": "Prakasam",
      "city_id": 1
    },
    {
      "city_name": "Pratapgarh",
      "city_id": 22
    },
    {
      "city_name": "Proddatur",
      "city_id": 1
    },
    {
      "city_name": "Pudukkottai",
      "city_id": 19
    },
    {
      "city_name": "Pulwama",
      "city_id": 10
    },
    {
      "city_name": "Pune",
      "city_id": 15
    },
    {
      "city_name": "Puri",
      "city_id": 16
    },
    {
      "city_name": "Purnea",
      "city_id": 3
    },
    {
      "city_name": "Purulia",
      "city_id": 23
    },
    {
      "city_name": "Puttur",
      "city_id": 12
    },
    {
      "city_name": "Quilon",
      "city_id": 13
    },
    {
      "city_name": "Raebareli",
      "city_id": 22
    },
    {
      "city_name": "Raichur",
      "city_id": 12
    },
    {
      "city_name": "Raigarh",
      "city_id": 5
    },
    {
      "city_name": "Raigarh",
      "city_id": 15
    },
    {
      "city_name": "Raipur",
      "city_id": 5
    },
    {
      "city_name": "Rajahmundry",
      "city_id": 1
    },
    {
      "city_name": "Rajauri",
      "city_id": 10
    },
    {
      "city_name": "Rajkot",
      "city_id": 7
    },
    {
      "city_name": "Rajsamand",
      "city_id": 18
    },
    {
      "city_name": "Ramanathapuram",
      "city_id": 19
    },
    {
      "city_name": "Rampur",
      "city_id": 22
    },
    {
      "city_name": "Rampur Bushahr",
      "city_id": 9
    },
    {
      "city_name": "Ratlam",
      "city_id": 14
    },
    {
      "city_name": "Ratnagiri",
      "city_id": 15
    },
    {
      "city_name": "Reasi",
      "city_id": 10
    },
    {
      "city_name": "Rewa",
      "city_id": 14
    },
    {
      "city_name": "Rohtak",
      "city_id": 8
    },
    {
      "city_name": "Rohtas",
      "city_id": 3
    },
    {
      "city_name": "Ropar",
      "city_id": 17
    },
    {
      "city_name": "Rudraprayag",
      "city_id": 21
    },
    {
      "city_name": "Rupnagar",
      "city_id": 17
    },
    {
      "city_name": "Sabarkantha",
      "city_id": 7
    },
    {
      "city_name": "Sagar",
      "city_id": 14
    },
    {
      "city_name": "Saharanpur",
      "city_id": 22
    },
    {
      "city_name": "Saharsa",
      "city_id": 3
    },
    {
      "city_name": "Salem East",
      "city_id": 19
    },
    {
      "city_name": "Salem West",
      "city_id": 19
    },
    {
      "city_name": "Samastipur",
      "city_id": 3
    },
    {
      "city_name": "Sambalpur",
      "city_id": 16
    },
    {
      "city_name": "Sangareddy",
      "city_id": 20
    },
    {
      "city_name": "Sangli",
      "city_id": 15
    },
    {
      "city_name": "Sangrur",
      "city_id": 17
    },
    {
      "city_name": "Sant Kabir Nagar",
      "city_id": 22
    },
    {
      "city_name": "Sant Ravidas Nagar",
      "city_id": 22
    },
    {
      "city_name": "Santhal Parganas",
      "city_id": 11
    },
    {
      "city_name": "Saran",
      "city_id": 3
    },
    {
      "city_name": "Satara",
      "city_id": 15
    },
    {
      "city_name": "Sawai Madhopur",
      "city_id": 18
    },
    {
      "city_name": "Secunderabad",
      "city_id": 20
    },
    {
      "city_name": "Sehore",
      "city_id": 14
    },
    {
      "city_name": "Shahdol",
      "city_id": 14
    },
    {
      "city_name": "Shahjahanpur",
      "city_id": 22
    },
    {
      "city_name": "Shimla",
      "city_id": 9
    },
    {
      "city_name": "Shimoga",
      "city_id": 12
    },
    {
      "city_name": "Shrawasti",
      "city_id": 22
    },
    {
      "city_name": "Sibsagar",
      "city_id": 2
    },
    {
      "city_name": "Siddharthnagar",
      "city_id": 22
    },
    {
      "city_name": "Sikar",
      "city_id": 18
    },
    {
      "city_name": "Sindhudurg",
      "city_id": 15
    },
    {
      "city_name": "Singhbhum",
      "city_id": 11
    },
    {
      "city_name": "Sirohi",
      "city_id": 18
    },
    {
      "city_name": "Sirsi",
      "city_id": 12
    },
    {
      "city_name": "Sitamarhi",
      "city_id": 3
    },
    {
      "city_name": "Sitapur",
      "city_id": 22
    },
    {
      "city_name": "Sivaganga",
      "city_id": 19
    },
    {
      "city_name": "Siwan",
      "city_id": 3
    },
    {
      "city_name": "Solan",
      "city_id": 9
    },
    {
      "city_name": "Solapur",
      "city_id": 15
    },
    {
      "city_name": "Sonbhadra",
      "city_id": 22
    },
    {
      "city_name": "Sonepat",
      "city_id": 8
    },
    {
      "city_name": "Sonitpur",
      "city_id": 2
    },
    {
      "city_name": "South 24 Parganas",
      "city_id": 23
    },
    {
      "city_name": "South Dinajpur",
      "city_id": 23
    },
    {
      "city_name": "Srikakulam",
      "city_id": 1
    },
    {
      "city_name": "Srinagar",
      "city_id": 10
    },
    {
      "city_name": "Srirangam",
      "city_id": 19
    },
    {
      "city_name": "Sultanpur",
      "city_id": 22
    },
    {
      "city_name": "Sundargarh",
      "city_id": 16
    },
    {
      "city_name": "Surat",
      "city_id": 7
    },
    {
      "city_name": "Surendranagar",
      "city_id": 7
    },
    {
      "city_name": "Suryapet",
      "city_id": 20
    },
    {
      "city_name": "Tadepalligudem",
      "city_id": 1
    },
    {
      "city_name": "Tambaram",
      "city_id": 19
    },
    {
      "city_name": "Tamluk",
      "city_id": 23
    },
    {
      "city_name": "Tarn Taran",
      "city_id": 17
    },
    {
      "city_name": "Tehri Garhwal",
      "city_id": 21
    },
    {
      "city_name": "Tenali",
      "city_id": 1
    },
    {
      "city_name": "Thalassery",
      "city_id": 13
    },
    {
      "city_name": "Thane",
      "city_id": 15
    },
    {
      "city_name": "Thanjavur",
      "city_id": 19
    },
    {
      "city_name": "Theni",
      "city_id": 19
    },
    {
      "city_name": "Tinsukia",
      "city_id": 2
    },
    {
      "city_name": "Tiruchirapalli",
      "city_id": 19
    },
    {
      "city_name": "Tirunelveli",
      "city_id": 19
    },
    {
      "city_name": "Tirupati",
      "city_id": 1
    },
    {
      "city_name": "Tirupattur",
      "city_id": 19
    },
    {
      "city_name": "Tirupur",
      "city_id": 19
    },
    {
      "city_name": "Tirur",
      "city_id": 13
    },
    {
      "city_name": "Tiruvalla",
      "city_id": 13
    },
    {
      "city_name": "Tiruvannamalai",
      "city_id": 19
    },
    {
      "city_name": "Tonk",
      "city_id": 18
    },
    {
      "city_name": "Trichur",
      "city_id": 13
    },
    {
      "city_name": "Trivandrum North",
      "city_id": 13
    },
    {
      "city_name": "Trivandrum South",
      "city_id": 13
    },
    {
      "city_name": "Tumkur",
      "city_id": 12
    },
    {
      "city_name": "Tuticorin",
      "city_id": 19
    },
    {
      "city_name": "Udaipur",
      "city_id": 18
    },
    {
      "city_name": "Udham Singh Nagar",
      "city_id": 21
    },
    {
      "city_name": "Udhampur",
      "city_id": 10
    },
    {
      "city_name": "Udupi",
      "city_id": 12
    },
    {
      "city_name": "Ujjain",
      "city_id": 14
    },
    {
      "city_name": "Una",
      "city_id": 9
    },
    {
      "city_name": "Unnao",
      "city_id": 22
    },
    {
      "city_name": "Uttarkashi",
      "city_id": 21
    },
    {
      "city_name": "Vadakara",
      "city_id": 13
    },
    {
      "city_name": "Vadodara East",
      "city_id": 7
    },
    {
      "city_name": "Vadodara West",
      "city_id": 7
    },
    {
      "city_name": "Vaishali",
      "city_id": 3
    },
    {
      "city_name": "Valsad",
      "city_id": 7
    },
    {
      "city_name": "Varanasi",
      "city_id": 22
    },
    {
      "city_name": "Vellore",
      "city_id": 19
    },
    {
      "city_name": "Vidisha",
      "city_id": 14
    },
    {
      "city_name": "Vijayawada",
      "city_id": 1
    },
    {
      "city_name": "Virudhunagar",
      "city_id": 19
    },
    {
      "city_name": "Visakhapatnam",
      "city_id": 1
    },
    {
      "city_name": "Vizianagaram",
      "city_id": 1
    },
    {
      "city_name": "Vriddhachalam",
      "city_id": 19
    },
    {
      "city_name": "Wanaparthy",
      "city_id": 20
    },
    {
      "city_name": "Warangal",
      "city_id": 20
    },
    {
      "city_name": "Wardha",
      "city_id": 15
    },
    {
      "city_name": "Washim",
      "city_id": 15
    },
    {
      "city_name": "West Champaran",
      "city_id": 3
    },
    {
      "city_name": "Yavatmal",
      "city_id": 15
    }
  ]
  
  export const states = [
    {state_name: "Andhra Pradesh", state_id: 1},
    {state_name: "Assam", state_id: 2},
    {state_name: "Bihar", state_id: 3},
    {state_name: "Chandigarh",  state_id: 4},
    {state_name: "Chhattisgarh", state_id:  5},
    {state_name: "Delhi",  state_id: 6},
    {state_name: "Gujarat",  state_id: 7},
    {state_name: "Haryana",  state_id: 8},
    {state_name: "Himachal Pradesh",  state_id: 9},
    {state_name: "Jammu & Kashmir",  state_id: 10},
    {state_name: "Jharkhand",  state_id: 11},
    {state_name: "Karnataka",  state_id: 12},
    {state_name: "Kerala",  state_id: 13},
    {state_name: "Madhya Pardesh",  state_id: 14},
    {state_name: "Maharashtra",  state_id: 15},
    {state_name: "Odisha",  state_id: 16},
    {state_name: "Punjab",  state_id: 17},
    {state_name: "Rajasthan",  state_id: 18},
    {state_name: "Tamil Nadu", state_id: 19},
    {state_name: "Telangana",  state_id: 20},
    {state_name: "Uttarakhand",  state_id: 21},
    {state_name: "Uttar Pardesh", state_id: 22},
    {state_name: "West Bengal",  state_id: 23}
  ]
  
  export const profession = [
      "Private Sector",
      "Government Sector",
      "Student",
      "Bussinessmen",
      "Self Employed",
  ]
  
  export const speciality = [
    "batting",
    "bowling",
    "wicket_keeper",
    "all_rounder"
  ]