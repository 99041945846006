export const sportsDisplayName = {
  CRICKET: "Cricket",
  FOOTBALL: "Football (Coming Soon)",
  BADMINTON: "Badminton (Coming Soon)",
};

export const genderDisplayName = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
};

export const sportsRole = {
  CRICKET: ["BATTING", "BOWLING", "WICKET_KEEPER", "ALL_ROUNDER"],
  // 'Football': ['DEFENDER', 'FORWARD', 'GOAL_KEEPER']
};

export const sportsRoleDisplayName = {
  //Cricket
  BATTING: "Batsman",
  BOWLING: "Bowler",
  WICKET_KEEPER: "Wicket Keeper",
  ALL_ROUNDER: "All Rounder",
};

export const sportsCategories = ["CRICKET", "FOOTBALL", "BADMINTON"];
export const activeSports = ["CRICKET"];
export const professionOptions = [
  "Private Sector",
  "Government Sector",
  "Student",
  "Bussinessmen",
  "Self Employed",
  "Other",
];
