export const getCookie = (key) => {
  var name = key + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (key, value) => {
  const datetime = new Date();
  datetime.setDate(datetime.getDate() + 1);
  document.cookie = `${key}=${value}; expires=${datetime}; domain=shauryasports.com; path=/; Max-Age=86400;`;
};

export const deleteCookie = (key) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=shauryasports.com; path=/; Max-Age=0;`;
};
