import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import mixpanel from "mixpanel-browser";

import Auth from "../components/authentication/index";
import OnBoarding from "../components/authentication/onboarding";
import ForgotPassword from "../components/forgot-password";
import TrialDetails from "../components/trial-details";
import Trials from "../components/trials";
import Dashboard from "../components/dashboard";
import Tournament from "../components/tournaments";
import League from "../components/league";
import Profile from "../components/profile";
import MyTrials from "../components/my-trials";
import MyTransactions from "../components/transactions";
import MyVideos from "../components/my-videos";
import EventVideos from "../components/my-videos/listing";
import Page404 from "../components/page404";
import Notifications from "../components/notifications";
import Updates from "../components/updates";
import Championship from "../components/tournament-details";
import TopPlayers from "../components/top-players";
import MatchDetails from "../components/tournament-details/matches/details";

class Routers extends React.Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
  }

  componentDidMount() {
    this.sendGaPageView(this.history.location.pathname);
    this.sendMixpanelPageView(this.history.location.pathname);

    this.history.listen((location) => {
      this.sendGaPageView(location.pathname);
      this.sendMixpanelPageView(location.pathname);
    });
  }

  sendGaPageView = (pagePath = null) => {
    // Not firing on localhost
    if (pagePath && window.location.href.indexOf("localhost") == -1) {
      if (window.gtag) window.dataLayer.push({ event: "pageview" });
    }
  };

  sendMixpanelPageView = (pagePath = null) => {
    // Not firing on localhost
    if (pagePath && window.location.href.indexOf("localhost") == -1) {
      mixpanel.track("page_view", {
        pagepath: pagePath,
      });
    }
  };

  render = () => {
    return (
      <Router history={this.history}>
        <Switch>
          <Route exact path="/" component={Auth} />
          <Route path="/auth" component={Auth} />
          <Route path="/generate_password" component={ForgotPassword} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/tournament/:id/matches" component={Championship} />
          <Route path="/selection-tournament/:id" component={Championship} />
          <Route path="/tournaments" component={Tournament} />
          <Route path="/trial/:id" component={TrialDetails} />
          <Route path="/trials" component={Trials} />
          <Route path="/my-trials" component={MyTrials} />
          <Route path="/my-videos/:event/:id" component={EventVideos} />
          <Route path="/my-videos" component={MyVideos} />
          <Route path="/league" component={League} />
          <Route path="/onboarding" component={OnBoarding} />
          <Route path="/profile" component={Profile} />
          <Route path="/my-transactions" component={MyTransactions} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/updates" component={Updates} />
          <Route path="/match-details/:id" component={MatchDetails} />
          <Route path="/top-players" component={TopPlayers} />
          <Route component={Page404} />
        </Switch>
      </Router>
    );
  };
}
export default Routers;
