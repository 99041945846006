import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Navbar } from "../../shared";
import {
  validate_email,
  validate_mobile,
  validate_password,
} from "../../handlers/validateHandler";
import {
  getCookie,
  setCookie,
  deleteCookie,
} from "../../handlers/cookieHandler";
import "./styles.css";

import {
  checkUser,
  loginCall,
  signupCall,
  signupCompleteCall,
  resetUserDetails,
} from "../../actions/index";

class Auth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      access_token: undefined,
      onboarding: undefined,
      is_exists: undefined,
      email: "",
      refCode: "",
      mobile: "",
      password: "",
      confirm_password: "",
      verification: false,
      otp: "",
      signup_with: "",
      resendDisable: false,
      seconds: 30,
      refFieldVisible: false,
      loader: {
        user_exists: false,
        loading: false,
        signup: false,
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const access_token = localStorage.getItem("auth_token");
    const onboarding = localStorage.getItem("onboarding");
    this.props.resetUserDetails();
    if (!!access_token && !!onboarding) {
      this.props.history.push("/dashboard");
    } else if (!!access_token) {
      this.props.history.push("/onboarding");
    }
    if (this.props.location.state !== undefined) {
      const { email, mobile } = this.props.location.state;
      this.props.checkUser({
        email,
        mobile,
      });
      this.setState({ email, mobile, signup_with: email ? "email" : "mobile" });
    }

    let refCode = "";
    var fetchCookie = getCookie("referral");
    if (fetchCookie) {
      refCode = getCookie("referral");
    }
    if (refCode) {
      this.setState({
        refFieldVisible: true,
        refCode: refCode,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { is_exists, signed_up, access_token, onboarding, loader } =
      this.props;
    if (
      is_exists !== prevProps.is_exists ||
      signed_up !== prevProps.signed_up ||
      access_token !== prevProps.access_token ||
      onboarding !== prevProps.onboarding ||
      loader !== prevProps.loader
    ) {
      this.setState({
        is_exists,
        signed_up,
        access_token,
        onboarding,
        loader,
      });
      if (onboarding && access_token) {
        this.props.history.push("/dashboard");
      } else if (access_token) {
        this.props.history.push("/onboarding");
      }
    }
  }

  resendOtp = () => {
    const { mobile, email, signup_with } = this.state;
    this.props.checkUser({
      email: signup_with === "email" ? email : "",
      mobile: signup_with === "email" ? "" : mobile,
    });
    this.setState({
      resendDisable: true,
    });
    this.decrementCounter();
  };

  decrementCounter = () => {
    var intervalId = setInterval(this.timer, 1000);
    this.setState({ intervalId: intervalId });
  };

  timer = () => {
    if (this.state.seconds !== 1) {
      this.setState((prevState) => ({
        seconds: prevState.seconds - 1,
      }));
    } else {
      clearInterval(this.state.intervalId);
      this.setState({
        resendDisable: false,
        seconds: 30,
      });
    }
  };

  forgotPassword = () => {
    this.props.history.push("/generate_password", {
      email: this.state.email,
      mobile: this.state.mobile,
      signup_with: this.state.signup_with,
    });
  };

  handleInputs = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEmailInput = (e) => {
    let value = e.target.value ? e.target.value.toLowerCase() : "";
    this.setState({
      [e.target.name]: value,
    });
  };

  handleCheckEmail = (e) => {
    let value = e.target.value.toLowerCase();
    this.setState({
      [e.target.name]: value,
      mobile: "",
    });
  };

  handleCheckMobile = (event) => {
    let mobile = event.target.value;
    if (!isNaN(mobile) && mobile.length <= 10) {
      this.setState({
        mobile,
        email: "",
      });
    }
  };

  showRefField = () => {
    this.setState({ refFieldVisible: !this.state.refFieldVisible });
  };

  handleRefField = (event) => {
    let code = event.target.value;
    this.setState({ refCode: code });
  };

  checkUser = () => {
    const { email, mobile, refCode } = this.state;
    if (mobile) {
      var check = validate_mobile(mobile);
      if (check) {
        let savedCode = getCookie("referral");
        if (savedCode !== refCode) {
          setCookie("referral", refCode);
        }
        this.props.checkUser({
          email,
          mobile,
        });
        this.setState({
          loader: {
            ...this.state.loader,
            user_exists: true,
          },
          signup_with: "mobile",
        });
      } else {
        this.setState({
          mobile: "",
        });
      }
    } else {
      window.error("Mobile field is required");
    }
  };

  signupUser = () => {
    const {
      email,
      mobile,
      password,
      confirm_password,
      otp,
      signup_with,
      is_exists,
    } = this.state;
    if (email && mobile && otp && password && confirm_password) {
      const validateData =
        validate_email(email) &&
        validate_mobile(mobile) &&
        validate_password(password, confirm_password);
      if (validateData) {
        this.props[is_exists ? "signupCompleteCall" : "signupCall"]({
          email,
          mobile,
          password,
          otp,
          signup_with,
        });
        this.setState({ loader: { ...this.state.loader, signup: true } });
      }
    } else {
      window.error("All Fields are mandatory!".getText());
    }
  };

  loginUser = () => {
    const { email, mobile, password } = this.state;
    if (password) {
      if (email || mobile) {
        this.props.loginCall({
          email,
          mobile,
          password,
        });
        this.setState({ loader: { ...this.state.loader, login: true } });
      }
    } else {
      window.error("Password is required!".getText());
    }
  };

  renderIsExists = () => {
    // <div className="field">
    //   <p className="is-size-6 mb-2 form_label">{"Email Address".getText()}</p>
    //   <div className="control">
    //     <input
    //       className="input is-medium"
    //       type="text"
    //       placeholder="Your Email"
    //       value={this.state.email}
    //       name="email"
    //       onChange={this.handleCheckEmail} />
    //   </div>
    // </div>
    // <p className="mt-2 mb-2 is-flex jc">{"OR".getText()}</p>
    return (
      <div className="column is-6 basic_info_form is-relative">
        <h1 className="is-size-2 pc has-text-weight-bold">
          {"Hello!".getText()}
        </h1>
        <p className="mb-5 sc has-text-weight-semibold">
          {"Signup / Login".getText()}
        </p>

        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light has-text-weight-semibold">
            {"Phone Number".getText()}
          </p>
          <div className="control">
            <input
              className="input is-medium"
              type="text"
              name="mobile"
              maxLength="10"
              placeholder="Eg - 9876543210"
              value={this.state.mobile}
              onChange={this.handleCheckMobile}
            />
          </div>
        </div>

        {/* <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
            marginBottom: "16px",
          }}
          onClick={this.showRefField}
        >
          <p className="has-text-grey-light is-size-7">Got a Referral Code</p>
          {this.state.refFieldVisible ? (
            <img
              src={require("../../images/up-arrow.png")}
              style={{ height: "12px", width: "12px", marginLeft: "8px" }}
            />
          ) : (
            <img
              src={require("../../images/down-arrow.png")}
              style={{ height: "12px", width: "12px", marginLeft: "8px" }}
            />
          )}
        </div> */}

        {/* {this.state.refFieldVisible ? (
          <div className="field">
            <p className="is-size-6 mb-2 form_label has-text-grey-light has-text-weight-semibold">
              {"Reference Code".getText()}
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                name="refField"
                placeholder="3GFV567DJB"
                value={this.state.refCode}
                onChange={this.handleRefField}
              />
            </div>
          </div>
        ) : null} */}

        <p className="pc" style={{ fontSize: "14px", marginTop: "40px" }}>
          {"By proceeding, you agree to our ".getText()}
          <span>
            <a
              target="_blank"
              href="https://www.shauryasports.com/privacy-policy"
              className="sc"
              style={{ color: "#3753a4", cursor: "default" }}
            >
              {" Privacy Policy, ".getText()}
            </a>
          </span>
          <span>
            <a
              target="_blank"
              href="https://www.shauryasports.com/refund-policy"
              className="sc"
              style={{ color: "#3753a4", cursor: "default" }}
            >
              {"Refund Policy".getText()}
            </a>
          </span>
          {" and".getText()}
          <span>
            <a
              target="_blank"
              className="sc"
              href="https://www.shauryasports.com/terms-conditions"
              style={{ color: "#3753a4", cursor: "default" }}
            >
              {" Terms & Conditions.".getText()}
            </a>
          </span>
        </p>
        <div className="is-flex jc">
          <button
            className={
              this.state.loader.user_exists
                ? "button top_section_register_button auth-action-button is-loading"
                : "button top_section_register_button auth-action-button"
            }
            onClick={this.checkUser}
          >
            {"Proceed".getText()}
          </button>
        </div>
      </div>
    );
  };

  renderLogin = () => {
    const { email, mobile, signup_with } = this.state;
    return (
      <div className="column is-6 basic_info_form is-relative">
        <h1 className="is-size-2 pc fcb_b has-text-weight-semibold">
          {"Hello!".getText()}
        </h1>
        <p className="mb-5 sc has-text-weight-semibold">{"Login".getText()}</p>
        {signup_with === "email" ? (
          <div className="field">
            <p className="is-size-6 mb-2 form_label has-text-white has-text-weight-semibold">
              {"Email Address".getText()}
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Eg - sachin@god.com"
                value={email}
                name="email"
                disabled
              />
            </div>
          </div>
        ) : (
          <div className="field">
            <p className="is-size-6 mb-2 form_label pc has-text-weight-semibold">
              {"Phone Number".getText()}
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Eg - 9876543210"
                value={mobile}
                name="mobile"
                disabled
              />
            </div>
          </div>
        )}

        <div className="field">
          <p className="is-size-6 mb-2 form_label pc has-text-weight-semibold">
            {"Password".getText()} *
          </p>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              name="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleInputs}
            />
          </div>
        </div>

        <div className="field">
          <div style={{ color: "#3753A4", textAlign: "right" }}>
            <p
              className="pc has-text-weight-semibold"
              style={{ cursor: "default" }}
              onClick={this.forgotPassword}
            >
              {"Forgot Password".getText()}
            </p>
          </div>
        </div>

        <div className="is-flex jc">
          <button
            className={
              this.state.loader.login
                ? "button top_section_register_button auth-action-button is-loading"
                : "button top_section_register_button auth-action-button"
            }
            onClick={this.loginUser}
          >
            {"Login".getText()}
          </button>
        </div>
      </div>
    );
  };

  renderSignup = () => {
    const { signup_with, email, mobile } = this.state;
    let displayValue = signup_with === "email" ? email : mobile;
    return (
      <div className="column is-6 basic_info_form is-relative">
        <h1 className="is-size-2 pc fcb_b has-text-weight-semibold">
          {"Hello!".getText()}
        </h1>
        <p className="mb-5 has-text-weight-semibold has-text-grey-light">
          {"Signup with".getText()} <span className="sc">{displayValue}</span>
        </p>
        {signup_with !== "email" && (
          <div className="field">
            <p className="is-size-6 mb-2 form_label has-text-grey-light">
              {"Email Address".getText()}
              <span style={{ color: "#3753a4" }}>*</span>
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Eg - sachin@god.com"
                value={this.state.email}
                name="email"
                onChange={this.handleEmailInput}
              />
            </div>
          </div>
        )}

        {signup_with !== "mobile" && (
          <div className="field">
            <p className="is-size-6 mb-2 form_label has-text-grey-light">
              {"Phone Number".getText()}
              <span style={{ color: "#3753a4" }}>*</span>
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="number"
                placeholder="Eg - 9876543210"
                name="mobile"
                value={this.state.mobile}
                onChange={this.handleInputs}
              />
            </div>
          </div>
        )}

        <div>
          <div className="field">
            <p className="is-size-6 mb-2 form_label has-text-grey-light">
              {"One Time Password".getText()} ({"sent on your".getText()}{" "}
              {signup_with.getText()})
              <span style={{ color: "#3753a4" }}>*</span>
            </p>
            <div className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Enter OTP"
                value={this.state.otp}
                name="otp"
                maxLength="6"
                onChange={this.handleInputs}
              />
            </div>
            {!this.state.resendDisable ? (
              <div className="field mt-2">
                <div style={{ color: "#3753A4", textAlign: "right" }}>
                  <p
                    className="has-text-grey-light"
                    style={{ cursor: "default" }}
                    onClick={this.resendOtp}
                  >
                    {"Resend OTP".getText()}
                  </p>
                </div>
              </div>
            ) : (
              <div className="field mt-2">
                <div style={{ color: "#3753A4", textAlign: "right" }}>
                  <p className="text-muted">
                    {"Resend otp in".getText()} : {this.state.seconds} sec
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light">
            {"Create Password".getText()}
            <span style={{ color: "#3753a4" }}>*</span>
          </p>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              name="password"
              placeholder={"Should be greater than 6 characters".getText()}
              value={this.state.password}
              onChange={this.handleInputs}
            />
          </div>
        </div>

        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light">
            {"Confirm Password".getText()}
            <span style={{ color: "#3753a4" }}>*</span>
          </p>
          <div className="control">
            <input
              className="input is-medium"
              type="password"
              name="confirm_password"
              placeholder={"Must be same as password".getText()}
              value={this.state.confirm_password}
              onChange={this.handleInputs}
            />
          </div>
        </div>

        <div>
          <button
            className={
              this.state.loader.signup
                ? "button top_section_register_button auth-action-button is-loading"
                : "button top_section_register_button auth-action-button"
            }
            onClick={this.signupUser}
          >
            {"Signup".getText()}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { is_exists, signed_up } = this.state;
    return (
      <div>
        <Navbar />
        <section className="signup is-relative container">
          <div className="columns basic_info_wrapper">
            {is_exists === undefined
              ? this.renderIsExists()
              : is_exists && signed_up
              ? this.renderLogin()
              : this.renderSignup()}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { ...auth };
};

export default withRouter(
  connect(mapStateToProps, {
    checkUser,
    loginCall,
    signupCall,
    signupCompleteCall,
    resetUserDetails,
  })(Auth)
);
