export const validate_email = email => {
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      return true;
    }
    window.error("You have entered an invalid email address!");
    return (false)
  }
  
  export const validate_mobile = mobile => {
      const phoneNo = /^\d{10}$/;
    if(mobile.match(phoneNo)){
      return true;
    }
    window.error("You have entered an invalid mobile number!");
    return false;
  }
  
  export const validate_password = ( password, confirmPassword ) => {
      if(password.length > 6){
          if(password === confirmPassword){
              return true;
          }else{
              window.error("Password Mismatch!")
              return false;
          }
      }	
      window.error("Password must be greater than 6 characters!"); 
      return false;
  }