const INITIAL_STATE = {
  allMatches: [],
  matchDetails: {},
  loading: {
    allMatches: true,
    matchDetails: true,
  },
};

export default INITIAL_STATE;
