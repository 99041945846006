import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { images } from "../../images";
import { TabBar, MobNavbar } from "../../shared";
import { getAllTrails } from "../../actions";
import ShauryaLoader from "../../elements/shauryaLoader";
import "./styles.css";

class AllTrials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allTrials: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.props.getAllTrails();
  };

  componentDidUpdate = (prevProps) => {
    const { allTrials, loading } = this.props;
    if (prevProps.allTrials !== allTrials) {
      this.setState({ allTrials: [...allTrials], loading });
    }
  };

  getStatusTag = (status) => {
    if (status === "UPCOMING") {
      return (
        <span className="tag sc  mt-2" style={{ color: "#fff" }}>
          Upcoming
        </span>
      );
    } else if (status === "COMPLETED") {
      return (
        <span
          className="tag mt-2 has-text-weight-semibold"
          style={{ backgroundColor: "#313131", color: "#fff" }}
        >
          COMPLETED
        </span>
      );
    } else {
      return (
        <span
          className="tag blink-tag mt-2"
          style={{ backgroundColor: "#ff1e50", color: "#fff" }}
        >
          Live Now
        </span>
      );
    }
  };

  render() {
    const { allTrials, loading } = this.state;
    if (loading) {
      return <ShauryaLoader />;
    }
    return (
      <>
        <MobNavbar title="All Trials" subHeading="Top Performers and more" />
        <div className="container trial-container">
          <div className="columns is-flex-wrap-wrap mt-3 league-teams p-2">
            {allTrials.map((event) => {
              return (
                <div
                  key={event["_id"]}
                  className="column is-3 has-text-centered"
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/trial/${event["_id"]}`,
                      state: { name: event.name },
                    })
                  }
                >
                  <div
                    className="team-card position-relative has-text-centered"
                    style={{ minHeight: "150px" }}
                  >
                    <img
                      src={event.image || images.logo_theme}
                      alt="team"
                      className="m-auto"
                      width="40"
                    />
                    <h3 className="has-text-weight-bold pc is-size-7 mt-3">
                      {event.name}
                    </h3>
                    <div className="trial-status">
                      {this.getStatusTag(event.static_data.status)}
                    </div>
                    <div className="trial-stats is-flex is-flex-direction-row is-align-items-center is-justify-content-space-evenly">
                      <div className="">
                        <p className="is-size-7 has-text-weight-bold">
                          {event.registrations}
                        </p>
                        <p className="is-size-8 has-text-weight-medium has-text-primary-dark">
                          Registrations
                        </p>
                      </div>
                      <div>
                        <p className="is-size-7 has-text-weight-bold">
                          {event.attendence}
                        </p>
                        <p className="is-size-8 has-text-weight-medium has-text-primary-dark">
                          Attended
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <TabBar />
      </>
    );
  }
}

const mapStateToProps = ({ trial }) => {
  return {
    allTrials: trial.allTrials,
    loading: trial.loading.allTrials,
  };
};

export default connect(mapStateToProps, {
  getAllTrails,
})(withRouter(AllTrials));
