export const getTabs = (selectionMatch) => {
  if (selectionMatch) {
    return [
      { key: "teams", value: "Teams" },
      { key: "matches", value: "Matches" },
      { key: "standings", value: "Standings" },
    ];
  }
  return [
    { key: "league", value: "League" },
    { key: "selection", value: "Selection Matches" },
    { key: "teams", value: "Teams" },
    { key: "matches", value: "Matches" },
    { key: "standings", value: "Standings" },
  ];
};
