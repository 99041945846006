import INITIAL_STATE from "./models/transaction";
import { ALL_TRANSACTIONS } from "../actionTypes";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: [...action.payload.transactions],
        transactionsLoading: false,
      };

    default:
      return state;
  }
};
