import mixpanel from 'mixpanel-browser';

import { razorpay_key } from '../config';
import { registerEvent } from '../actions';

export const eventRegistration = ({ tournament_id = null, trial_id, amount, eventName }) => {
  if (amount) {
    let name = localStorage.getItem("name");
    let email = localStorage.getItem("email");
    let mobile = localStorage.getItem("mobile");

    mixpanel.track("register_trial", {
      action: "click",
    });

    var options = {
      key: razorpay_key,
      amount: eval(amount) * 100,
      currency: "INR",
      name: "Shaurya Sports",
      description: `Payment For ${eventName}`,
      handler: (response) => {
        registerEvent({
          tournament_id,
          trial_id,
          payment_id: response.razorpay_payment_id
        },
          (success, message) => {
            if (!success) {
              alert(message)
            }
            setTimeout(() => {
              window.location.reload();
            }, 800);
          }
        );
        mixpanel.track("payment_done", {
          action: "system",
        });
      },
      prefill: {
        name: name || "",
        email: email || "",
        contact: mobile || "",
      },
      theme: {
        color: "#3753a4",
      },
    };

    var rzp = new window.Razorpay(options);
    rzp.open();
  } else {
    alert("Unable to process the payment! Please try after some time.");
  }
};