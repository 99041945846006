import React from "react";

import ShauryaLoader from "../../elements/shauryaLoader";
import { TabBar, MobNavbar } from "../../shared";
import "./styles.css";

const Layout = (props) => {
  let { loading, pageTitle, pageSubtitle } = props;
  return (
    <>
      <MobNavbar title={pageTitle} subHeading={pageSubtitle} />
      {loading ? (
        <ShauryaLoader />
      ) : (
        <div className="container layout-container">{props.children}</div>
      )}
      <TabBar />
    </>
  );
};

export default Layout;
