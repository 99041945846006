import React, { Component, Fragment } from 'react';
// import icons from "../assets/icons";
class YTPlayer extends Component {
  state = {}

  renderYTPlayer = () => {
    const { source, show, type } = this.props;
    if (show) {
      return (
        <div style={playerStyles.videoPlayerWrapper}>
          <div style={playerStyles.close} onClick={this.props.handlePlayerClose}>
            <img
              alt="close"
              src="https://img.icons8.com/ios-glyphs/2x/ffffff/macos-close.png"
              style={playerStyles.closeIcon} />
          </div>
          {
            type === "youtube" ? (
              <iframe
                width="560"
                height="315"
                id="yt_player"
                src={source}
                title="visible_frame"
                frameBorder="0"
                style={playerStyles.videoPlayer}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            ) : (
              <video width="100%" height="100%" controls autoPlay>
                <source src={source} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          }
        </div>
      )
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderYTPlayer()}
      </Fragment>
    )
  }
}

const playerStyles = {
  videoPlayerWrapper: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,0.99)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,
  },
  videoPlayer: {
    width: "100%",
    height: "100%",
  },
  closeIcon: {
    width: 30,
    height: 30,
    cursor: "pointer"
  },
  close: {
    position: "fixed",
    top: 20,
    right: 20,
    zIndex: 10999999,
    cursor: "pointer",
    // background: "#fff",
    borderRadius: "50%",
    height: "30px",
  }
};

export default YTPlayer;
