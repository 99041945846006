import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabBar, MobNavbar } from "../../shared";
import { eventRegistration } from "../../handlers/razorpay";
import ShauryaLoader from "../../elements/shauryaLoader";
import "./styles.css";

import { getProfile, getDashboard } from "../../actions";
import { deleteCookie } from "../../handlers/cookieHandler";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTournaments: props.activeTournaments,
      activeTrials: props.activeTrials,
      user: props.user,
      loading: props.loading,
    };
  }

  componentDidMount = () => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      this.props.history.push("/");
    } else {
      this.props.getDashboard();
      this.props.getProfile();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { activeTournaments, activeTrials, user, loading } = this.props;
    if (prevProps.loading !== loading) {
      this.setState({ activeTournaments, activeTrials, user, loading });
    }
  };

  tournamentCard = () => {
    const { activeTournaments } = this.state;
    return activeTournaments.map((tournament, index) => {
      let events = tournament["structure"];
      let currentPaymentIndex = undefined;
      for (let i = 0; i < events.length; i++) {
        if (!events[i]["payment_done"]) {
          currentPaymentIndex = i;
          break;
        }
      }
      return (
        <div key={tournament["_id"]} className="thanks-view">
          <div className="text-center trial-hero is-flex is-justify-content-center is-align-items-center">
            <div className="columns is-desktop">
              <div className="column is-12">
                <div className="p-5">
                  <img
                    src={tournament.image}
                    height="48px"
                    width="48px"
                    alt="tournament_image"
                    className="mb-2"
                  />
                  <p className="is-size-5 has-text-weight-bold pc content-title">
                    {tournament.static_data.title}
                  </p>
                  <p className="is-size-7 has-text-weight-medium pc content-title mt-3">
                    {tournament.static_data.description}
                  </p>
                  {tournament.eligible ? (
                    <p
                      style={{ color: "green" }}
                      className="is-size-7 has-text-weight-medium content-title mt-3"
                    >
                      Congratulation! You have qualified for the Championship League.
                    </p>
                  ) : null}
                </div>
                {tournament.structure.map((event, index) => {
                  return (
                    <div key={index.toString()} style={{ padding: 16 }}>
                      <div
                        style={{
                          borderRadius: "12px",
                          backgroundColor: "#f9f9f9",
                          textAlign: "center",
                          marginBottom: "16px",
                          padding: "12px",
                        }}
                      >
                        <p className="pc has-text-weight-bold is-size-6">
                          {tournament.name} -{" "}
                          <span className="sc">{event.name}</span>
                        </p>
                        <div className="trial-hero-footer-payment is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                          {event.payment_done ? (
                            <div className="is-flex is-flex-direction-row is-align-items-center">
                              <i className="fa fa-check-circle has-text-primary-dark"></i>
                              <p className="is-size-7 has-text-weight-semibold has-text-primary-dark">
                                Successfully Registered
                              </p>
                            </div>
                          ) : event.payment_egligible ? (
                            <>
                              <button
                                disabled={
                                  currentPaymentIndex === index ? false : true
                                }
                                onClick={() => {
                                  eventRegistration({
                                    tournament_id: tournament["_id"],
                                    trial_id:
                                      tournament["selection_trial"]["_id"],
                                    amount: event["amount"],
                                    eventName: tournament.name,
                                  });
                                }}
                                className="button has-text-weight-semibold is-small is-primary is-rounded mt-1 is-inverted is-outlined all-sports-btn"
                              >
                                Register Now {event.amount}/-
                              </button>
                              {/* <button
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: `tournament/${tournament["_id"]}/matches`,
                                    state: {
                                      name: tournament.selection_trial.name,
                                      tabName: "selection",
                                    },
                                  });
                                }}
                                className="button is-small is-link is-rounded ml-3 pcb"
                              >
                                More Info
                              </button> */}
                            </>
                          ) : (
                            <p
                              style={{ color: "red" }}
                              className="is-size-7 mb-2 has-text-weight-medium content-title mt-3"
                            >
                              Sorry, you are not eligible for this event !
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button
                  onClick={() => {
                    this.props.history.push({
                      pathname: `tournament/${tournament["_id"]}/matches`,
                      state: {
                        name: tournament.name,
                        // tabName: "camp-info",
                      },
                    });
                  }}
                  className="button is-small is-link is-rounded mb-4 pcb"
                >
                  More Info
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  trialCard = () => {
    const { activeTrials } = this.state;
    return activeTrials.map((trial, index) => {
      return (
        <div key={index.toString()} className="thanks-view">
          <div
            className="text-center trial-hero is-justify-content-center is-align-items-center"
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          >
            <div className="columns is-desktop">
              <div className="column is-12">
                <div className="p-5">
                  <p className="is-size-5 has-text-weight-bold pc content-title">
                    {trial.static_data.title}
                  </p>
                  <p className="is-size-7 has-text-weight-medium pc content-title mt-3">
                    {trial.static_data.description}
                  </p>
                </div>
                <div
                  style={{
                    position: "relative",
                    padding: "0px 16px",
                    width: "100%",
                  }}
                >
                  {trial.static_data.upcoming.length ? (
                    <p
                      className="pc"
                      style={{
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      <u>{trial.static_data.status} EVENTS</u>
                    </p>
                  ) : null}

                  {trial.static_data.upcoming.map((item, index) => (
                    <div
                      style={{
                        borderRadius: "12px",
                        backgroundColor: "#f9f9f9",
                        textAlign: "center",
                        marginBottom: "16px",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <div>
                        <p className="sc">Trial State:</p>
                        <p className="pc">{item.region}</p>
                      </div>
                      <div style={{ margin: "10px 0px" }}>
                        <p className="sc">Venue:</p>
                        <p
                          style={{
                            margin: "0px 40px",
                            textDecorationLine: "underline",
                          }}
                        >
                          <a href={item.venue_location} target="blank">
                            {item.venue}
                          </a>
                        </p>
                      </div>
                      <div>
                        <p className="sc">Reporting Date & Time:</p>
                        <p className="pc">{item.reporting_datetime}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="trial-hero-footer is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                  {trial.paid ? (
                    trial.payment_done ? (
                      <div className="is-flex is-flex-direction-row is-align-items-center">
                        <i className="fa fa-check-circle has-text-primary-dark"></i>
                        <p className="is-size-7 has-text-weight-semibold has-text-primary-dark ml-2">
                          Successfully Registered for {trial.name}.
                        </p>
                      </div>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            eventRegistration({
                              trial_id: trial["_id"],
                              eventName: trial.name,
                              amount: trial["amount"],
                            });
                          }}
                          className="button has-text-weight-semibold is-small is-primary is-rounded mt-1 mb-1 is-inverted is-outlined all-sports-btn"
                        >
                          Register Now for {trial.amount}/-
                        </button>
                        <button
                          onClick={() => {
                            this.props.history.push({
                              pathname: `trial/${trial["_id"]}`,
                              state: { name: trial.name },
                            });
                          }}
                          className="button is-small is-link is-rounded ml-3 pcb"
                        >
                          More Info
                        </button>
                      </>
                    )
                  ) : (
                    <button
                      onClick={() => {}}
                      className="button is-primary is-rounded mt-1 mb-1 is-inverted is-outlined all-sports-btn"
                    >
                      Register Now for Free
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  renderDashboard = () => {
    const { activeTournaments, activeTrials, user } = this.state;
    return (
      <>
        <a
          href="https://play.google.com/store/apps/details?id=com.shaurya_cricket"
          target="_blank"
        >
          <div className="androidBanner">
            <div style={{ width: "60%" }}>
              <p className="is-size-5 has-text-weight-bold pc content-title">
                Shaurya Sports Android App.
              </p>
              <p className="is-size-5 has-text-weight-bold sc content-title">
                Get it on Google Play.
              </p>
            </div>
          </div>
        </a>
        <div>
          {activeTrials.length ? (
            <>
              <p className="is-size-6 is-uppercase pc dashboard-labels has-text-weight-bold">
                Trials
              </p>
              {this.trialCard()}
            </>
          ) : null}
        </div>
        <div>
          {activeTournaments.length ? (
            <>
              <p className="is-size-6 is-uppercase pc dashboard-labels has-text-weight-bold">
                Tournaments
              </p>
              {this.tournamentCard()}
            </>
          ) : null}
        </div>
      </>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <MobNavbar
          title="Dashboard"
          subHeading="Match Summary, trial details at one place"
        />
        <div className="hero dashboard-container">
          {loading ? <ShauryaLoader /> : this.renderDashboard()}
        </div>
        <TabBar />
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard }) => {
  return {
    activeTournaments: dashboard.activeTournaments,
    activeTrials: dashboard.activeTrials,
    user: dashboard.user,
    loading: dashboard.dashboardLoading,
  };
};

export default connect(mapStateToProps, {
  getProfile,
  getDashboard,
})(withRouter(Profile));
