import React, { Component } from 'react';
import { TabBar, MobNavbar } from '../../shared';
import TopTrialPlayers from "../../elements/topTrialPlayers";
import "./styles.css";

class TopPlayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false
    };
  }

  render() {
    return (
      <div className="mob-top-players">
        <MobNavbar title="Player's Ranking" subHeading="leaderboard of top players from trials" />
        <TopTrialPlayers placement="list" />
        <TabBar />
      </div>
    );
  }
}

const styles = {
  bigNewsSection: {
    border: "1px solid #f0f0f0",
    borderRadius: 6,
    boxShadow: "0 21px 50px rgba(0, 0, 0, 0.08)",
  },
  bigNews: {
    height: "300px",
    backgroundColor: "#eee",
    width: "100%",
    objectFit: "cover",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  gridContainer: {
    display: "grid",
    marginTop: "30px",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "0.9em",
  },
  gridContainer2: {
    display: "grid",
    marginTop: "30px",
    gridTemplateColumns: "repeat(1fr)",
    gridGap: "0.9em",
  },
  newsItem: {
    border: "1px solid #f0f0f0",
    borderRadius: 4,
    boxShadow: "0 21px 50px rgba(0, 0, 0, 0.08)",
  },
  modalBody: {
    padding: '24px',
    backgroundColor: " #fff",
    borderRadius: '8px',
  },
  modalContent: {
    height: '60vh',
    overflow: 'scroll'
  },
  modalFooter: {
    height: '10vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default TopPlayers;
