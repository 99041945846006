import React from 'react';
const SHLoader = () => {
  return (
    <div className="shauryaLoaderWrapper">
      <img
        className="shauryaLoader"
        src="https://i.imgur.com/v2N1MJn.png"
        alt="Sharya loader" />
    </div>
  )
}

export default SHLoader;