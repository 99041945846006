import axios from "axios";
import { base_url } from "../config";
import { error_handler } from "../handlers/errorHandler";

import {
  GET_MY_PROFILE,
  UPDATE_MY_PROFILE,
  GET_USER_NOTIFICATIONS,
  GET_ALL_UPDATES,
  GET_MY_MEDIA,
} from "../actionTypes";

// get my profile
export const getProfile = () => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  return (dispatch) => {
    axios
      .get(base_url + "my_profile", config)
      .then(function (response) {
        if (response.status === 200) {
          const {
            profile: { name, email, mobile },
          } = response.data.data;
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);
          localStorage.setItem("mobile", mobile);
          dispatch({
            type: GET_MY_PROFILE,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_MY_PROFILE,
          payload: {},
        });
      });
  };
};

export const updateProfile = (content) => {
  let auth_token = localStorage.getItem("auth_token");
  let data = content;
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  return (dispatch) => {
    axios
      .put(base_url + "my_profile", data, config)
      .then(function (response) {
        if (response.status === 200) {
          window.success("Profile Updated Successfully !");
          dispatch({
            type: UPDATE_MY_PROFILE,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: UPDATE_MY_PROFILE,
          payload: {},
        });
      });
  };
};

// check all Notifications
export const getUserNotifications = () => {
  let config = {
    headers: {
      "X-Auth-Token": localStorage.getItem("auth_token"),
    },
  };
  return (dispatch) => {
    axios
      .get(base_url + "my_notifications", config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_USER_NOTIFICATIONS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_USER_NOTIFICATIONS,
          payload: {},
        });
      });
  };
};

// check all Updates
export const getAllUpdates = () => {
  return (dispatch) => {
    axios
      .get(
        base_url + "liveupdates"
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_UPDATES,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_ALL_UPDATES,
          payload: {},
        });
      });
  };
};

//User Media
export const getMyMedia = () => {
  let config = {
    headers: {
      "X-Auth-Token": localStorage.getItem("auth_token"),
    },
  };
  return (dispatch) => {
    axios
      .get(base_url + "my_media", config)
      .then(function (response) {
        if (response.status === 200) {
          console.log("response", response);
          dispatch({
            type: GET_MY_MEDIA,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_MY_MEDIA,
          payload: {},
        });
      });
  };
};
