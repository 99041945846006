import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Navbar } from '../../shared';
import { validate_password } from "../../handlers/validateHandler";
import {
  forgotPasswordCall,
  resetOTPMessage,
  changePasswordCall
} from '../../actions';

import './styles.css';

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    // Set Initial Local State
    this.state = {
      resendDisable: false,
      seconds: 30,
      email: "",
      mobile: "",
      password: "",
      confirm_password: "",
      signup_with: "",
      otp: "",
      loader: {
        change_password: false
      }
    };
  }

  static getDerivedStateFromProps = ({
    otpSent,
    message,
    passwordUpdated
  }) => ({
    otpSent,
    message,
    passwordUpdated
  });

  componentDidMount = () => {
    if (!this.props.location.state) {
      window.location = "/auth";
    }
    const { location: {
      state: {
        email,
        mobile,
        signup_with,
      },
    },
    } = this.props;
    this.setState({
      email,
      mobile,
      signup_with,
    });
    this.props.forgotPasswordCall({
      email,
      mobile
    });
  };

  componentDidUpdate = prevProps => {
    const { otpSent, message, passwordUpdated, email, mobile } = this.state;
    const { loader } = this.props;
    if (loader !== prevProps.loader) {
      this.setState({ loader });
    }
    if (message) {
      if (!otpSent || !passwordUpdated) {
        window.error(message);
      } else window.success(message);
    }
    this.props.resetOTPMessage();
    if (passwordUpdated) {
      this.props.history.push("/auth", {
        email,
        mobile,
      });
    }
  };

  resend_otp = () => {
    const { mobile, email } = this.state;
    this.props.forgotPasswordCall({
      email,
      mobile
    });
    this.setState({
      resendDisable: true
    })
    this.decrementCounter();
  }

  decrementCounter = () => {
    let intervalId = setInterval(this.timer, 1000);
    this.setState({ intervalId: intervalId });
  }

  timer = () => {
    if (this.state.seconds !== 1) {
      this.setState(prevState => ({
        seconds: prevState.seconds - 1
      }))
    } else {
      clearInterval(this.state.intervalId);
      this.setState({
        resendDisable: false,
        seconds: 30
      })
    }
  }

  handleInputs = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  generatePassword = () => {
    const { email, mobile, otp, password, confirm_password } = this.state;
    if (otp && password && confirm_password) {
      const validate = validate_password(password, confirm_password);
      if (validate) {
        this.props.changePasswordCall({
          email,
          mobile,
          password,
          otp
        });
        this.setState({
          loader: {
            ...this.state.loader,
            change_password: true
          }
        });
      }
    } else {
      window.error("All fields are mandatory!".getText())
    }
  }

  renderBasicForm = () => {
    return (
      <div className="column is-5 basic_info_form is-relative">
        <h1 className="is-size-3 pc fcb_b has-text-weight-semibold">
          {"Forgot Password ?".getText()}
        </h1>
        <p className="mb-5 is-size-7 sc has-text-weight-semibold">
          {"Enter OTP sent on "}
          '{
            this.state.signup_with === "email"
              ? this.state.email
              : this.state.mobile
          }' {"and create new password".getText()}.
        </p>

        {/*...............OTP...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light has-text-weight-semibold">{"Enter OTP *".getText()}</p>
          <div className="control">
            <input
              name="otp"
              className="input is-medium"
              type="text"
              onChange={this.handleInputs}
              placeholder="eg - 123456" />
          </div>
        </div>

        {
          !this.state.resendDisable
            ? <div>
              <div style={{ color: "#3753A4", textAlign: "right" }}>
                <p
                  className="has-text-grey-light has-text-weight-semibold"
                  style={{ cursor: "default" }} onClick={this.resend_otp}>
                  {"Resend OTP".getText()}
                </p>
              </div>
            </div>
            : <div>
              <div style={{ color: "#3753A4", textAlign: "right" }}>
                <p className="text-muted has-text-grey-light has-text-weight-semibold">{"Resend otp in".getText()} : {this.state.seconds} {"sec".getText()}</p>
              </div>
            </div>
        }

        {/*...............Password...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light has-text-weight-semibold">
            {"Create New Password *".getText()}
          </p>
          <div className="control">
            <input
              name="password"
              className="input is-medium"
              type="password"
              onChange={this.handleInputs}
              placeholder="Password" />
          </div>
        </div>
        {/*...............Repeat Password...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label has-text-grey-light has-text-weight-semibold">
            {"Confirm New Password *".getText()}
          </p>
          <div className="control">
            <input
              name="confirm_password"
              className="input is-medium"
              type="password"
              onChange={this.handleInputs}
              placeholder="Repeat Password" />
          </div>
        </div>

        {/*...............Proceed...............*/}
        <div>
          <button
            className={
              this.state.loader.change_password
                ? "button top_section_register_button auth-action-button is-loading"
                : "button top_section_register_button auth-action-button"}
            onClick={this.generatePassword}>{"Create Password".getText()}</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Navbar navigation={this.props.history} />
        <div className="f-pass columns basic_info_wrapper">
          {this.renderBasicForm()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { ...auth };
};

export default withRouter(connect(mapStateToProps, {
  forgotPasswordCall,
  resetOTPMessage,
  changePasswordCall,
})(ForgotPassword));