import React, { Component } from "react";
import { connect } from "react-redux";
import { TabBar, MobNavbar } from "../../shared";
import SHLoader from "../../elements/shauryaLoader";
import { getMyTrails } from "../../actions";
import { images } from "../../images";

class MyTrials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTrials: [],
      showDetails: {},
      myTrialsLoading: true,
    };
  }

  componentDidMount = () => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      this.props.history.push("/");
    } else {
      this.props.getMyTrails();
    }
  };

  componentDidUpdate = (prevProps) => {
    let { myTrials, loading } = this.props;
    if (prevProps.myTrials !== myTrials) {
      let obj = {};
      if (myTrials.length) {
        myTrials.forEach((item) => {
          obj[item["trial"]["_id"]] = false;
        });
      }
      this.setState({
        myTrials: myTrials,
        showDetails: obj,
        myTrialsLoading: loading.myTrials,
      });
    }
  };

  toggleDetails = (trialId) => {
    let dump = { ...this.state.showDetails };
    dump = {
      ...dump,
      [trialId]: !this.state.showDetails[trialId],
    };
    this.setState({
      showDetails: { ...dump },
    });
  };

  render() {
    const { myTrials, showDetails, myTrialsLoading } = this.state;
    return (
      <>
        {/* <Navbar /> */}
        <MobNavbar title="My Trials" subHeading="Your Recent trials & scores" />
        <div
          className="hero my-trial-container"
          style={{ paddingTop: "70px", paddingBottom: "90px" }}
        >
          {myTrialsLoading ? (
            <SHLoader />
          ) : (
            <div>
              {myTrials.length === 0 ? (
                <div className="my-trial-info-block-loader">
                  <img src={images.no_trial} width="400" alt="no-data-image" />
                  <h4 className="mt-4 has-text-grey">
                    You have not attended any trial.
                  </h4>
                </div>
              ) : (
                <div>
                  {myTrials.map((item) => {
                    let trial = item["trial"];
                    return (
                      <div key={trial["_id"]}>
                        <div className="columns is-desktop">
                          <div className="column is-8 is-offset-2 text-center">
                            <div className="notification mb-0 my-trial-info-block">
                              <h1 className="score-title has-text-weight-bold mb-0 mt-0">
                                {item["score"] ? item["score"] : `0.00`}
                              </h1>
                              <p
                                style={{ marginTop: "-8px" }}
                                className="is-size-6 has-text-weight-semibold mb-4 has-text-grey-light"
                              >
                                Your Trial Score
                              </p>
                              <p className="is-size-5 has-text-weight-semibold pc mt-5 mb-1 sc">
                                {trial["name"]},{" "}
                                <span className="has-text-grey-light">
                                  {item["city"]}
                                </span>
                              </p>
                              {item.attended ? (
                                <p className="is-size-7 has-text-grey-light has-text-weight-medium">
                                  Attended On{" "}
                                  {new Date(
                                    item["attended_datetime"]
                                  ).toDateString()}
                                </p>
                              ) : (
                                <p className="is-size-7 has-text-grey-light has-text-weight-medium">
                                  Not Attended Yet
                                </p>
                              )}
                              <p className="is-size-7 has-text-grey-light has-text-weight-medium">
                                * Scores above 1 are good
                              </p>
                            </div>
                          </div>
                        </div>
                        {item.scoring.length > 0 && (
                          <div className="per-ball-score-container columns">
                            <div className="column is-offset-2 is-8">
                              <div className="score-table">
                                <div className="score-table-head is-flex is-flex-direction-row is-justify-content-space-around is-align-content-center">
                                  <div className="has-text-weight-semibold has-text-white">
                                    Player Type
                                  </div>
                                  <div className="has-text-weight-semibold has-text-white">
                                    Competency
                                  </div>
                                  <div className="has-text-weight-semibold has-text-white">
                                    Type
                                  </div>
                                  <div className="has-text-weight-semibold has-text-white">
                                    Score (Shaurya)
                                  </div>
                                </div>
                                <div className="score-table-body">
                                  {item.scoring.map((val, index) => {
                                    return (
                                      <div
                                        className="score-table-body-item"
                                        key={index.toString()}
                                      >
                                        <div className="is-capitalized has-text-weight-medium">
                                          {val["player_type"]}
                                        </div>
                                        <div className="is-capitalized has-text-weight-medium">
                                          {val["competency"]}{" "}
                                          {val["player_type"] == "batsman"
                                            ? "Bowling"
                                            : "Batting"}
                                        </div>
                                        <div className="is-capitalized has-text-weight-medium">
                                          {val["shot_type"]}
                                        </div>
                                        <div className="is-capitalized">
                                          <strong className="has-text-white">
                                            {val["score"]}
                                          </strong>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <TabBar />
      </>
    );
  }
}

const mapStateToProps = ({ trial }) => {
  return {
    myTrials: trial.myTrials,
    loading: trial.loading,
  };
};

export default connect(mapStateToProps, {
  getMyTrails,
})(MyTrials);
