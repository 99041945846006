import React from "react";
import { useSelector } from "react-redux";

const TournamentInfo = ({ league }) => {
  const { staticContent, trialDetails } = useSelector((state) => state.trial);
  let staticData = trialDetails?.static_data || { video: "", schedule: [] };
  return (
    <div className="pl-5 pr-5 mob-about-championship">
      {staticData.video ? (
        <iframe
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="300px"
          type="text/html"
          className="championshipTrailer"
          src={trialDetails.static_data.video}
        ></iframe>
      ) : null}
      {staticData.schedule.length ? (
        <div style={{ marginBottom: "40px" }}>
          <h2
            className="has-text-weight-bold is-size-5"
            style={{ color: "#000", marginBottom: "16px" }}
          >
            Trials Schedule :
          </h2>
          <div class="table-container">
            <table className="table is-bordered is-striped is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="pc">Name</th>
                  <th className="pc">Region</th>
                  <th className="pc">Venue</th>
                  <th className="pc">Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {staticData.schedule.map((item) => (
                  <tr key={item.name}>
                    <td style={{ minWidth: "120px" }}>{item.name}</td>
                    <td
                      style={{ minWidth: "120px" }}
                      className="has-text-black is-size-7"
                    >
                      {item.region}
                    </td>
                    <td
                      style={{ minWidth: "120px" }}
                      className="has-text-black is-size-7"
                    >
                      {item.venue_location ? (
                        <a href={item.venue_location} target={"_blank"}>
                          {item.venue}
                        </a>
                      ) : (
                        item.venue
                      )}
                    </td>
                    <td
                      style={{ minWidth: "120px" }}
                      className="has-text-black is-size-7"
                    >
                      {item.reporting_datetime}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: staticContent }} />
    </div>
  );
};

export default TournamentInfo;
