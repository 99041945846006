import axios from "axios";

import { ALL_TRANSACTIONS } from "../actionTypes";
import { base_url } from "../config";
import { error_handler } from "../handlers/errorHandler";

export const registerEvent = (
  { tournament_id = null, trial_id, payment_id },
  cb
) => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  let data = { trial_id, payment_id };
  let suffix = "my_trials";
  if (tournament_id) {
    data["tournament_id"] = tournament_id;
    suffix = "tournament_payment";
  }
  axios
    .post(base_url + suffix, data, config)
    .then(function (response) {
      if (response.status === 200) {
        cb(true, "Payement Successful");
      }
    })
    .catch(function (error) {
      error_handler(error);
      cb(false, error.message);
    });
};

export const myPayments = () => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  return (dispatch) => {
    axios
      .get(base_url + "my_transactions", config)
      .then(function (response) {
        if (response.status === 200) {
          let {
            data: {
              data: { transactions },
            },
          } = response;
          dispatch({
            type: ALL_TRANSACTIONS,
            payload: { transactions },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: ALL_TRANSACTIONS,
          payload: { transactions: null },
        });
      });
  };
};
