//Authentication
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";
export const OTP_EMAIL = "OTP_EMAIL";
export const OTP_MOBILE = "OTP_MOBILE";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_MOBILE = "VERIFY_MOBILE";

export const ONBOARDING = "ONBOARDING";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//Updates and Notifications
export const GET_CHAT_HELP = "GET_CHAT_HELP";
export const GET_ALL_UPDATES = "GET_ALL_UPDATES";
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";

//Profile
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_MY_SETTINGS = "GET_MY_SETTINGS";
export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";
export const GET_MY_MEDIA = "GET_MY_MEDIA";

//Trials
export const GET_MY_TRIALS = "GET_MY_TRIALS";
export const REGISTER_TRIAL = "REGISTER_TRIAL";
export const GET_ALL_TRIALS = "GET_ALL_TRIALS";
export const GET_TRIAL_DETAILS = "GET_TRIAL_DETAILS";
export const GET_TOP_TRIAL_PLAYERS = "GET_TOP_TRIAL_PLAYERS";
export const GET_TRIAL_STATIC_CONTENT = "GET_TRIAL_STATIC_CONTENT";

//Tournament
export const MATCH_DETAILS = "MATCH_DETAILS";
export const ALL_TOURNAMENTS = "ALL_TOURNAMENTS";
export const TOURNAMENT_TEAMS = "TOURNAMENT_TEAMS";
export const TOURNAMENT_MATCHES = "TOURNAMENT_MATCHES";
export const TOURNAMENT_DETAILS = "TOURNAMENT_DETAILS";
export const GET_SELECTION_STATIC_CONTENT = "GET_SELECTION_STATIC_CONTENT";
export const GET_TOURNAMENT_STATIC_CONTENT = "GET_TOURNAMENT_STATIC_CONTENT";

//Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";

//Payment
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const ALL_TRANSACTIONS = "ALL_TRANSACTIONS";
