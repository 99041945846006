import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ShauryaLoader from "../../../elements/shauryaLoader";
import { images } from "../../../images";
import MatchCard from "./card";
import "./styles.css";

const Matches = (props) => {
  const {
    loading: { allMatches: allMatchesLoading },
    allMatches,
  } = useSelector(({ match: { loading, allMatches } }) => ({
    loading,
    allMatches,
  }));

  if (allMatchesLoading) {
    return <ShauryaLoader />;
  }

  return (
    <div className="all-matches-wrapper">
      {allMatches.length ? (
        allMatches.map((match, index) => (
          <MatchCard
            key={index.toString()}
            match={match}
            index={index.toString()}
          />
        ))
      ) : (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-5">
          <img src={images.cricket} width="30" />
          <p className="is-size-7 pc mt-2 ">Matches to be announced soon !</p>
        </div>
      )}
    </div>
  );
};

export default Matches;
