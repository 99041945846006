import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShauryaLoader from "../elements/topTrialPlayers";

const TopTrialPlayers = ({ placement, list }) => {

  return (
    <div className="top-trial-players">
      <div className="columns">
        <div className="column is-12 is-flex is-flex-wrap-wrap">
          {
            list.map((player, index) =>
              <div key={index.toString()} className="column is-6">
                <article className="media" key={index}>
                  <figure className="media-left">
                    <p className="image is-64x64 scb score-box">
                      <p className="is-size-5 has-text-weight-bold has-text-white">
                        {player.score.toFixed(2)}
                      </p>
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content mt-2">
                      <p className="mb-0">
                        <strong className="pc s-capitalized is-size-7">
                          {player.user.name ? player.user.name : "Shaurya member"}
                        </strong>
                      </p>
                      <p style={{ marginTop: -6 }}>
                        <small className="pc is-inline-block is-size-8 has-text-weight-semibold is-capitalized">
                          {player.user.city ? player.user.city : "-"}
                        </small>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            )
          }
        </div>
      </div>
      {/* {
        placement === "home" ? (
          <div className="has-text-centered">
            <a
              href='/top-players'
              className="button is-rounded has-text-weight-bold is-light pc mt-4 mb-4">
              See full list
            </a>
          </div>
        ) : (
          <div className="has-text-centered">
            <a
              href='/dashboard'
              className="button is-rounded has-text-weight-bold is-light pc mt-4 mb-4">
              Back to dashboard
            </a>
          </div>
        )
      } */}

    </div>
  )
}

export default TopTrialPlayers;