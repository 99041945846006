import React, { Component } from "react";
import { Provider } from "react-redux";
import mixpanel from "mixpanel-browser";

import { ToastContainer, toast } from "react-toastify";
import "./styles/index.css";
import "bulma/css/bulma.css";
import getText from "./handlers/languageHandler";
import { setCookie } from "./handlers/cookieHandler";

import store from "./store";
import Routers from "./routers";
// import InProgressPage from './components/inProgressPage';

import "react-toastify/dist/ReactToastify.css";

String.prototype.getText = function () {
  return getText(this);
};

window.success = toast.success;
window.error = toast.error;
window.warn = toast.warn;
window.info = toast.info;

class App extends Component {
  constructor(props) {
    super(props);
    let { location } = window;
    if (location && location.search) {
      const search = location.search;
      const referral = new URLSearchParams(search).get("referral");
      if (referral) {
        setCookie("referral", referral);
      }
    }
    mixpanel.init("8283842418b00389d94d49c0058fda96", { debug: true });
  }

  render() {
    return (
      <Provider store={store}>
        <ToastContainer position="bottom-center" style={{}} />
        <Routers />
      </Provider>
    );
  }
}

export default App;
