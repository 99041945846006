import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTournamentTeams } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import ShauryaLoader from "../../../elements/shauryaLoader";
import { images } from "../../../images";

const ChampionshipTeams = () => {
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [selectedTeam, setSelectedteam] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    loading: { allTeams: allTeamsLoading },
    tournamentDetails: { allTeams },
  } = useSelector(
    ({
      tournament: {
        loading,
        tournamentDetails: { allTeams },
      },
    }) => ({ loading, tournamentDetails: { allTeams } })
  );

  useEffect(() => {
    dispatch(getTournamentTeams(id));
  }, []);

  const openSelectedPlayersModal = (team) => {
    setIsModelOpen(!isModalOpen);
    setSelectedteam(team);
  };

  const selectedPlayers = () => {
    let arr = selectedTeam.players || [];
    if (isModalOpen) {
      return (
        <div className="modal is-active selected-players">
          <div className="modal-background"></div>
          <div className="modal-content has-text-centered pt-4 pb-4">
            <button
              className="modal-close is-large has-background-grey-light"
              onClick={() => openSelectedPlayersModal()}
              aria-label="close"
            ></button>
            <img src={selectedTeam.image} alt="team" width="120" />
            <h1 className="has-text-white is-size-4 has-text-weight-bold mb-4 pc">
              {selectedTeam.name}
            </h1>
            <table className="table has-text-centered">
              <thead>
                <tr>
                  <th className="pc">Player No</th>
                  <th className="pc">Player Name</th>
                  <th className="pc">City</th>
                  <th className="pc">State</th>
                </tr>
              </thead>
              <tbody>
                {arr.map((p, index) => (
                  <tr key={index.toString()}>
                    <th>{index + 1}</th>
                    <td className="has-text-black is-size-7">{p.name}</td>
                    <td className="has-text-black is-size-7">{p.city}</td>
                    <td className="has-text-black is-size-7">{p.state}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  if (allTeamsLoading) {
    return <ShauryaLoader />;
  }

  return (
    <div className="">
      {allTeams.length ? (
        <h1 className="pc has-text-centered is-size-5 has-text-weight-bold mt-5">
          LEAGUE TEAMS
        </h1>
      ) : null}
      <div className="columns is-flex-wrap-wrap mt-3 league-teams p-2">
        {allTeams.length ? (
          allTeams.map((a, index) => (
            <div
              key={index.toString()}
              className="column is-3 has-text-centered"
              onClick={() => openSelectedPlayersModal(a)}
            >
              <div className="team-card">
                <img src={a.image} alt="team" width="50" />
                <h3 className="has-text-weight-bold pc is-size-7 mt-3">
                  {a.name}
                </h3>
              </div>
            </div>
          ))
        ) : (
          <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-align-self-center  ml-auto mr-auto">
            <img src={images.cricket_png} width="30" />
            <p className="is-size-7 pc mt-2">Teams to be announced soon!</p>
          </div>
        )}
      </div>
      {isModalOpen && selectedPlayers()}
    </div>
  );
};

export default ChampionshipTeams;
