import React from "react";
import { useHistory } from "react-router-dom";

const MatchCard = ({ index, match }) => {
  const history = useHistory();

  const navigateTo = (matchId) => {
    if (!matchId) return;
    history.push(`/match-details/${matchId}`);
  };

  function getTossText() {
    let text = "Toss yet to take place.";
    if (match.toss) {
      let winningTeam = match.toss.team.name || "";
      let decision = match.toss.decision || "";
      if (decision === "batting") {
        text = `${winningTeam} selected to Bat first.`;
      } else if (decision === "fielding") {
        text = `${winningTeam} selected to Field first.`;
      }
    }
    return text;
  }

  function getOver(ballsPlayed = 0) {
    if (ballsPlayed % 6 === 0) {
      return ballsPlayed / 6;
    }
    return `${Math.floor(ballsPlayed / 6)}.${Math.floor(ballsPlayed % 6)}`;
  }

  return (
    <div
      className="match-item"
      key={index}
      onClick={
        match.toss
          ? () => {
              navigateTo(match._id);
            }
          : () => {}
      }
    >
      <div className="match-meta-details p-4 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
        <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
          <img
            className="match-team-logo"
            src={match.team_1.image}
            alt="delhi-avengers"
          />
          <span className="is-inline-block pc is-size-7 has-text-weight-bold mt-2">
            {match.team_1_stats.score}/{match.team_1_stats.out}
            <span className="has-text-grey has-text-weight-semibold is-size-8">
              &nbsp;({getOver(match.team_1_stats.balls)})
            </span>
          </span>
          <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
            {match.team_1.name}
          </span>
        </div>
        <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
          <span className="pc is-size-8 has-text-weight-semibold mb-4 is-uppercase">
            {match.name}
          </span>
          <h4 className="sc is-size-5 has-text-weight-bold">VS</h4>
          {!match.winner && match.toss && (
            <span className="tag blink-tag scb has-text-white">LIVE</span>
          )}
        </div>
        <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
          <img
            className="match-team-logo"
            src={match.team_2.image}
            alt="delhi-avengers"
          />
          <span className="is-inline-block pc is-size-7 has-text-weight-bold mt-2">
            {match.team_2_stats.score}/{match.team_2_stats.out}
            <span className="has-text-grey has-text-weight-semibold is-size-8">
              &nbsp;({getOver(match.team_2_stats.balls)})
            </span>
          </span>
          <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
            {match.team_2.name}
          </span>
        </div>
      </div>
      <div className="match-toss-details has-text-centered is-flex is-flex-direction-column pb-2 pt-2">
        {match.winner && (
          <span className="has-text-primary-dark is-size-8 has-text-weight-bold">
            {match.winner?.team?.name} Won !
          </span>
        )}
        <span
          className={
            match.winner
              ? "pc is-size-8 has-text-weight-medium match-winner-text"
              : "pc is-size-8 has-text-weight-medium"
          }
        >
          {getTossText()}
        </span>
      </div>
    </div>
  );
};

export default MatchCard;
