import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { states, cities } from '../../data.js';
import { Navbar } from '../../shared';
import {
  sportsDisplayName, 
  sportsRoleDisplayName,
  sportsCategories,
  activeSports,
  professionOptions,
  sportsRole
} from '../../constants/mappings';

import { onboardingCall, logout } from '../../actions';
import './styles.css';

class Onboarding extends Component {

  constructor(props){
    super(props);

    // Set Initial Local State
    this.state = {
      age: undefined,
      speciality: undefined,
      state: undefined,
      state_id: undefined,
      city: undefined,
      name: undefined,
      gender: undefined,
      sport: undefined,
      pincode: undefined,
      profession: undefined,
      profile: {},
      loader: {
        onboarding: false
      }
    };
  }

  componentDidMount(){
    const access_token = localStorage.getItem("auth_token");
    const onboarding = localStorage.getItem("onboarding");
    if (onboarding === "true" && access_token) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate(prevProps) {
    const access_token = localStorage.getItem("auth_token");
    const onboarding = localStorage.getItem("onboarding");
    const { loader } = this.props;
    if (loader !== prevProps.loader) {
      this.setState({ loader });
    }
    if (onboarding === "true" && access_token) {
      this.props.history.push("/dashboard");
    }
  }

  static getDerivedStateFromProps = (nextProps) => {
    return{
        ...nextProps.profile
    }
  }

  handleInputs = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  setSpeciality = (event) => {
    this.setState({
      speciality: event.target.value
    });
  };

  onPickerChange = (val, key) => {
    this.setState({
      [key]: val.target.value
    })
  }

  onStateChange = (e) => {
    const test = states.find(element => {
      if(element.state_name === e.target.value) {
        const state_id = element.state_id;
        this.setState({
          state: element.state_name,
          state_id
        })
      }
      return null;
    })
  }

  handleBasicInfo = () => {
    const { name, age, speciality, city, state, gender, sport, pincode, profession } = this.state;
    if(name && age && city && state && sport && speciality && gender && pincode && profession) {
      this.props.onboardingCall({
        name,
        age,
        speciality,
        gender,
        sport,
        profession,
        pincode,
        state,
        city,
    });
      this.setState({ loader: { ...this.state.loader, onboarding: true } });
    } else {
      window.error("All feilds are Mandatory".getText());
    }
  }

  logoutUser = () => {
    this.props.logout();
  }

  renderBasicForm = () => {
    return (
      <div className="column is-8 basic_info_form is-relative">
        <h1 className="is-size-3 fcb_b has-text-weight-semibold">{"Basic Information".getText()}</h1>
        <p className="mb-5">{/*.."Registrations closed for Delhi, UP & Gujarat.".getText()..*/}</p>
        {/*...............Name...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">Full Name *</p>
          <div className="control">
            <input
              className="input is-medium"
              name="name"
              onChange={this.handleInputs}
              type="text"
              value= {this.state.profile.username }
              placeholder="Eg - Sachin Tendulkar" />
          </div>
        </div>
        {/*...............Age...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">{"Your Age".getText()} *</p>
          <div className="control">
            <input
              name="age"
              className="input is-medium"
              type="text"
              value= {this.state.profile.age }
              onChange={this.handleInputs}
              placeholder="eg - 38"/>
          </div>
        </div>
        {/*...............Gender...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">{"GENDER".getText()} *</p>
          <div className="select is-rounded" style={{width:"100%"}}>
            <select required style={{width:"100%"}} onChange={(val) => {this.onPickerChange(val, 'gender')}}>
              <option value="" defaultValue data-default>{"Select your Gender".getText()}</option>
              <option value='MALE'>Male</option>
              <option value='FEMALE'>Female</option>
              <option value='OTHER'>Other</option>
            </select>
          </div>
        </div>
        {/*...............Profession...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">{"PROFESSION".getText()} *</p>
          <div className="select is-rounded" style={{width:"100%"}}>
            <select required style={{width:"100%"}} onChange={(val) => {this.onPickerChange(val, 'profession')}}>
            <option value="" defaultValue data-default>{"Select your Profession".getText()}</option>
              {
                professionOptions.map((role, index) =>
                  <option
                    key={index}
                    value={`${role}`}>
                    {role}
                  </option>
              )}
            </select>
          </div>
        </div>
        {/*...............Enrolling For...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">{"ENROLLING FOR".getText()} *</p>
          <div className="select is-rounded" style={{width:"100%"}}>
            <select required style={{width:"100%"}} onChange={(val) => {this.onPickerChange(val, 'sport')}}>
              <option value="" defaultValue data-default>{"Select your Sport".getText()}</option>
                {
                  sportsCategories.map((sport, index) =>
                    <option
                      key={index}
                      disabled={activeSports.includes(sport) ? false : true}
                      value={`${sport}`}>
                      {sportsDisplayName[sport]}
                    </option>
                )}
            </select>
          </div>
        </div>
        {/*............... Speciality ...............*/}

        {
          this.state.sport
          ? <div className="field">
              <p className="is-size-6 mb-2 form_label">{"Applying As".getText()} *</p>
              <div className="select is-rounded" style={{width:"100%"}}>
                <select required style={{width:"100%"}} onChange={(val) => {this.setSpeciality(val)}}>
                  <option value="" defaultValue data-default>{"Select your Role".getText()}</option>
                    {
                      sportsRole[this.state.sport].map((role, index) =>
                        <option
                          key={index}                         
                          value={role}>
                          {sportsRoleDisplayName[role]}
                        </option>
                    )}
                </select>
              </div>
            </div>
          : null
        }

       {/*............... State ...............*/}
        <div className="field">
          <p className="is-size-6 mb-2 form_label">{"State".getText()} *</p>
          <div className="select is-rounded" style={{width:"100%"}}>
            <select required style={{width:"100%"}} onChange={this.onStateChange}>
              <option value="" defaultValue data-default>{"Select your State".getText()}</option>
                {
                  states.map((state, index) =>
                    <option key={index} value={`${state.state_name}`}>{state.state_name}</option>
                )}
            </select>
          </div>
        </div>
      {/*............... City ...............*/}
      {
        !!this.state.state_id ?
          <div className="field">
            <p className="is-size-6 mb-2 form_label">{"City".getText()} *</p>
            <div className="select is-rounded" style={{width:"100%"}}>
              <select style={{width:"100%"}} name="city" onChange={this.handleInputs}>
                <option value="" disabled defaultValue hidden>{"Select your City".getText()}</option>
                  {
                    cities.map((city, index) => {
                       return(city.city_id === this.state.state_id ?
                          <option key={index} value={`${city.city_name}`}>{city.city_name}</option>
                        : null)
                    }
                  )}
              </select>
            </div>
          </div>
        :
          <div className="field">
            <p className="is-size-6 mb-2 form_label">{"City".getText()} *</p>
            <div className="select is-rounded" style={{width:"100%"}}>
              <select disabled style={{width:"100%"}}>
                <option
                  value=""
                  defaultValue
                  data-default>
                    {"Select your State First".getText()}
                </option>
              </select>
            </div>
          </div>
      }
      {/*...............PinCode...............*/}
      <div className="field">
          <p className="is-size-6 mb-2 form_label">{"PIN CODE".getText()} *</p>
          <div className="control">
            <input
              name="pincode"
              className="input is-medium"
              type="text"
              value= {this.state.pincode }
              onChange={this.handleInputs}
              placeholder="eg - 133001"/>
          </div>
        </div>
      {/*...............Proceed...............*/}
        <div className="text-center">
          <button
            className={
              this.state.loader.onboarding
                ? "button top_section_register_button auth-action-button is-loading"
                : "button top_section_register_button auth-action-button"
              }
            onClick={this.handleBasicInfo}>
            {"Proceed".getText()}
          </button>
        </div>
        <div style={{textAlign: "center", marginTop: "15px"}}>
          <a onClick={this.logoutUser} className="pc" style={{fontSize: "13.2px;margin-left: 5px;"}}>{"Back to Login".getText()}</a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Navbar />
        <section className="signup is-relative container">
          <div className="columns basic_info_wrapper">
            {this.renderBasicForm()}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, profile }) => {
  return { auth, profile };
};

export default withRouter(connect(mapStateToProps, {
  onboardingCall,
  logout
})(Onboarding));
