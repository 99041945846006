import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { images } from "../../images";

const logout = async () => {
  await localStorage.clear();
  window.location.href = "/";
};

const TabBar = () => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("/dashboard");
  const [activeSideMenu, setActiveSideMenu] = useState(false);

  useEffect(() => {
    setActiveTab(window.location.pathname);
  }, [activeTab]);

  const navigateTo = (route) => {
    const currentTab = window.location.pathname;
    history.push(`/${route}`);
  };

  const toggleMenu = () => {
    setActiveSideMenu(!activeSideMenu);
  };

  const getDevice = () => {
    let width = window.innerWidth;
    return width < 768 ? "mobile" : "desktop";
  };

  return (
    <div>
      <div className="tab-bar">
        {getDevice() === "desktop" && (
          <div className="tab-bar-item">
            <img
              onClick={() => navigateTo("dashboard")}
              className="tab-bar-logo"
              src={require("../../images/logo_theme.png")}
              width="50"
              title="Shaurya Sports"
              alt="logo"
            />
          </div>
        )}
        <div
          className={
            activeTab === "/trials" || activeTab.includes("/trial/")
              ? "tab-bar-item active"
              : "tab-bar-item"
          }
          onClick={() => navigateTo("trials")}
        >
          <img src={images.cricket_png} width="24" alt="my-trials" />
          <small className="is-size-7 pc has-text-weight-semibold">
            Trials
          </small>
        </div>
        <div
          className={
            activeTab === "/tournaments" ||
            activeTab.includes("/tournament/") ||
            activeTab.includes("/selection-tournament/")
              ? "tab-bar-item active"
              : "tab-bar-item"
          }
          onClick={() => navigateTo("tournaments")}
        >
          <img src={images.league_png} alt="league" />
          <small className="is-size-7 pc has-text-weight-semibold">
            League
          </small>
        </div>
        <div
          className={
            activeTab === "/dashboard" ? "tab-bar-item active" : "tab-bar-item"
          }
          onClick={() => navigateTo("dashboard")}
        >
          <img src={images.dashboard_png} alt="dashboard" />
          <small className="is-size-7 pc has-text-weight-semibold">
            Dashboard
          </small>
        </div>
        <div
          className={
            activeTab === "/updates" ? "tab-bar-item active" : "tab-bar-item"
          }
          onClick={() => navigateTo("updates")}
        >
          <img src={images.notification_png} alt="notification" />
          <small className="is-size-7 pc has-text-weight-semibold">
            Updates
          </small>
        </div>
        <div className="tab-bar-item" onClick={() => toggleMenu()}>
          <img src={images.menu_png} alt="menu" />
          <small className="is-size-7 pc has-text-weight-semibold">More</small>
        </div>
      </div>
      <div
        className={activeSideMenu ? "mob-side-menu active" : "mob-side-menu"}
      >
        <div className="mob-sidebar-items">
          <div
            className="p-4 is-flex is-align-items-center is-flex-direction-row"
            style={{ background: "#f1f5f9", cursor: "pointer" }}
            onClick={toggleMenu}
          >
            <p className="is-size-7 pc mr-2">Back</p>
            <img src={images.arrow_right_png} width="16" alt="arrow_right" />
          </div>

          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => navigateTo("profile")}
          >
            <img src={images.profile_png} width="24" alt="my-trials" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">
              My Profile
            </p>
          </div>
          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => navigateTo("my-trials")}
          >
            <img src={images.cricket_png} width="24" alt="my-trials" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">
              My Trials
            </p>
          </div>
          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => navigateTo("my-videos")}
          >
            <img src={images.dashboard_png} width="24" alt="my-videos" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">
              My Videos
            </p>
          </div>
          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => navigateTo("notifications")}
          >
            <img src={images.notification_png} width="24" alt="my-trials" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">
              My Notifications
            </p>
          </div>
          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => navigateTo("my-transactions")}
          >
            <img src={images.wallet_png} width="24" alt="transactions" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">
              My Transactions
            </p>
          </div>
          <div
            className="mob-sidebar-item is-flex is-align-items-center is-flex-direction-row"
            onClick={() => logout()}
          >
            <img src={images.logout_png} width="24" alt="my-trials" />
            <p className="is-size-7 pc has-text-weight-semibold ml-3">Logout</p>
          </div>
        </div>
        <div>
          <div className="mob-version has-text-centered">
            <img src={images.logo_theme} width="40" alt="logo" />
            <p className="is-size-7 pc has-text-weight-semibold">
              Shaurya Sports v1.1
            </p>
          </div>
          <div className="column is-12 mb-4">
            <div className="columns ai">
              <div className="column is-12 text-center">
                <a
                  href="https://www.facebook.com/shauryasports/"
                  target="_blank"
                >
                  <img
                    className="social_link_img"
                    style={{ height: "30px", width: "30px", margin: "0px 8px" }}
                    src={require("../../images/links/facebook1.png")}
                  />
                </a>
                <a href="https://instagram.com/shaurya_sports" target="_blank">
                  <img
                    className="social_link_img"
                    style={{ height: "30px", width: "30px", margin: "0px 8px" }}
                    src={require("../../images/links/instagram.png")}
                  />
                </a>
                <a
                  href="https://youtube.com/channel/UC6Uzit4MIZOeqhisnoFTx6A"
                  target="_blank"
                >
                  <img
                    className="social_link_img"
                    style={{ height: "30px", width: "30px", margin: "0px 8px" }}
                    src={require("../../images/links/youtube.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabBar;
