import React from 'react';

const Skeleton = () => {
  return (
    <>
      {
        [1, 2, 3, 4, 5].map((item) => {
          return (
            <div
              key={item}
              className="box"
              style={{ backgroundColor: '#f9f9f9', height: '60px', width: 'calc(100vw - 40px)', marginLeft: 20 }}>
            </div>
          )
        })
      }
    </>
  )
}

export default Skeleton;