import React, { useState } from "react";
import teams from './teams.json'

const League = () => {

  const [isModalOpen, setIsModelOpen] = useState(false)
  const [selectedTeam, setSelectedteam] = useState({});

  const selectedPlayers = () => {
    let arr = Array(12).fill("");
    if (isModalOpen) {
      return (
        <div className="modal is-active selected-players">
          <div className="modal-background"></div>
          <div className="modal-content has-text-centered pt-4 pb-4">
            <button
              className="modal-close is-large has-background-grey-light"
              onClick={() => openSelectedPlayersModal()}
              aria-label="close">
            </button>
            <img src={selectedTeam.logo} alt="team" width="120" />
            <h1 className="has-text-white is-size-4 has-text-weight-bold mb-4 pc">
              {selectedTeam.name}
            </h1>
            <table className="table has-text-centered">
              <thead>
                <tr>
                  <th className="pc">
                    Jersey
                  </th>
                  <th className="pc">Player Name</th>
                </tr>
              </thead>
              <tbody>
                {
                  arr.map((p, index) =>
                    <tr>
                      <th>{index + 1}</th>
                      <td className="has-text-black is-size-7">
                        Selection in progess
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  const openSelectedPlayersModal = (team) => {
    setIsModelOpen(!isModalOpen)
    setSelectedteam(team)
  }

  return (
    <div>
      <div className="container">
        <div className="league-hero is-flex">
          <div className="league-context is-flex is-justify-content-center is-align-items-flex-start is-flex-direction-column is-flex-grow-1">
            <img src={require("../../images/logo-white.png")} alt="shaurya-logo" className="shaurya-logo" />
            <h1 className="has-text-white is-size-1 has-text-weight-bold">
              Delhi NCR T-20 League
            </h1>
            <h3 className="pc is-size-4 has-text-weight-bold">
              Championship Starting in December
            </h3>
          </div>
          <div className="trophy-area">
            <img src="https://cdn-icons-png.flaticon.com/512/2641/2641497.png" alt="team" width="300" />
          </div>
        </div>
        <h1 className="pc has-text-centered is-size-2 has-text-weight-bold mt-5">LEAGUE TEAMS</h1>
        <div className="columns is-flex-wrap-wrap mt-5 league-teams">
          {
            teams.map((a, index) =>
              <div
                key={index}
                className="column is-3 has-text-centered"
                onClick={() => openSelectedPlayersModal(a)}>
                <div className="team-card">
                  <img src={a.logo} alt="team" width="200" />
                  <h3 className="has-text-weight-bold has-text-white is-size-5">
                    {a.name}
                  </h3>
                </div>
              </div>
            )
          }
        </div>
      </div>
      {isModalOpen && selectedPlayers()}
    </div>

  )
}

export default League;