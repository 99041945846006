import INITIAL_STATE from "./models/auth";
import {
  OTP_EMAIL,
  OTP_MOBILE,
  VERIFY_EMAIL,
  VERIFY_MOBILE,
  CHECK_EMAIL,
  LOGIN,
  LOGOUT,
  SIGNUP,
  ONBOARDING,
  RESET_USER_DETAILS,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD
} from "../actionTypes"; // Import Action Types

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OTP_EMAIL:
      return { ...state, ...action.payload };
    case OTP_MOBILE:
      return { ...state, ...action.payload };
    case VERIFY_EMAIL:
      return { ...state, ...action.payload };
    case VERIFY_MOBILE:
      return { ...state, ...action.payload };
    case CHECK_EMAIL:
      return { ...state, ...action.payload, loader: {...state.loader, user_exists: false} };
    case LOGIN:
      return { ...state, ...action.payload, loader: {...state.loader, login: false} };
    case SIGNUP:
      return { ...state, ...action.payload, loader: {...state.loader, signup: false} };
    case ONBOARDING: 
      return { ...state, ...action.payload, loader: { ...state.loader, onboarding: false } };
    case FORGOT_PASSWORD: 
      return { ...state, ...action.payload };  
    case CHANGE_PASSWORD: 
      return { ...state, ...action.payload, loader: { ...state.loader, change_password: false } };
    case RESET_USER_DETAILS:
      return{...INITIAL_STATE}   
    case LOGOUT: 
      return {...INITIAL_STATE};  
    default:
      return state;
  }
};
