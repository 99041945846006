import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TabBar, MobNavbar } from '../../shared';
import Skeleton from './card-skeleton';
import './styles.css';

import { getUserNotifications } from '../../actions';

class Notidates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userNotifications: [],
      userNotificationsLoading: true,
    }
  }

  componentDidMount = () => {
    this.props.getUserNotifications();
  }

  componentDidUpdate = (prevProps) => {
    const { userNotifications, loading } = this.props;
    if (prevProps.userNotifications !== userNotifications) {
      this.setState({
        userNotifications: userNotifications,
        userNotificationsLoading: loading.userNotifications
      });
    }
  }

  renderNotifications = () => {
    const { userNotifications } = this.state;
    if (!userNotifications.length) {
      return (
        <div className="text-center" style={{ height: '300px', margin: '100px 0px' }}>
          <strong className="pc">Notifications Empty!</strong>
        </div>
      )
    }
    return (
      userNotifications.map((item, index) => {
        return (
          <article className="media mob-notification-item" key={index.toString()}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={require('../../images/logo-white.png')} alt="Image" />
              </figure>
            </div>
            <div className="media-content">
              <div className="content">
                <strong className="has-text-white">{item.title}</strong>
                <small className="ml-2 has-text-grey is-size-7">
                  &middot; {new Date(item.updated_at).toLocaleDateString()}
                </small>
                <p className="has-text-grey-light is-size-7 has-text-weight-semibold mt-1">
                  {item.message}
                </p>
              </div>
            </div>
          </article>
        )
      })
    )
  }

  render() {
    const { userNotificationsLoading } = this.state;
    return (
      <div>
        <MobNavbar title="Notifications" subHeading="Get notified on every little update from us" />
        <div className="container mob-notification-container">
          <div className='content' style={{ minHeight: '70vh', marginTop: '30px' }}>
            {
              userNotificationsLoading ? <Skeleton /> : this.renderNotifications()
            }
          </div>
        </div>
        <TabBar />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return ({
    userNotifications: user.userNotifications,
    loading: user.loading
  })
}


export default connect(
  mapStateToProps,
  {
    getUserNotifications,
  }
)(withRouter(Notidates));
