import INITIAL_STATE from "./models/match";
import {
    TOURNAMENT_MATCHES,
    MATCH_DETAILS
} from "../actionTypes";

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case TOURNAMENT_MATCHES:
      return {
        ...state,
        allMatches: [...action.payload.matches],
        loading: { ...state.loading, allMatches: false }
      };
    case MATCH_DETAILS:
      return {
        ...state,
        matchDetails: {...action.payload.match},
        loading: { ...state.loading, matchDetails: false }
      };
    default:
      return state;
  }
};
