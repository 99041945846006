import INITIAL_STATE from "./models/trial";
import {
  GET_ALL_TRIALS,
  GET_MY_TRIALS,
  REGISTER_TRIAL,
  GET_TRIAL_DETAILS,
  GET_PAYMENT_METHOD,
  GET_TOP_TRIAL_PLAYERS,
  GET_TRIAL_STATIC_CONTENT,
} from "../actionTypes";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MY_TRIALS:
      return {
        ...state,
        myTrials: action.payload.trials,
        loading: { ...state.loading, myTrials: false },
      };

    case GET_ALL_TRIALS:
      return {
        ...state,
        allTrials: [...action.payload.all_trials],
        loading: { ...state.loading, allTrials: false },
      };

    case GET_TRIAL_DETAILS:
      return {
        ...state,
        trialDetails: { ...action.payload.trial_details },
        loading: { ...state.loading, trialDetails: false },
      };

    case REGISTER_TRIAL:
      let { myTrials } = state;
      let trialsCopy = [...myTrials];
      trialsCopy.push({ ...action.payload.trial });
      return { ...state, myTrials: trialsCopy };

    case GET_TOP_TRIAL_PLAYERS:
      return {
        ...state,
        topPlayers: [...action.payload.trials],
        loading: { ...state.loading, topPlayers: false },
      };

    case GET_PAYMENT_METHOD:
      return {
        ...state,
        payment: [...action.payload.payment],
        loading: { ...state.loading, payment: false },
      };

    case GET_TRIAL_STATIC_CONTENT:
      return {
        ...state,
        staticContent: action.payload.static_content,
        loading: { ...state.loading, staticContent: false },
      };
    default:
      return state;
  }
};
