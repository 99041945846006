import React from "react";
import { useSelector } from "react-redux";
import ShauryaLoader from "../../../../../elements/shauryaLoader";

const MatchMetaDetails = () => {
  const { loading: { matchDetails: matchDetailsLoading }, matchDetails } = useSelector(({
    match: { loading, matchDetails } }) => ({ loading, matchDetails }));


  function getTossText() {
    let text = "Toss yet to take place.";
    if (matchDetails.toss) {
      let winningTeam = matchDetails.toss.team.name || "";
      let decision = matchDetails.toss.decision || "";
      if (decision === "batting") {
        text = `${winningTeam} selected to Bat first.`;
      } else if (decision === "fielding") {
        text = `${winningTeam} selected to Field first.`;
      }
    }
    return text;
  }

  function getOver(ballsPlayed = 0) {
    if (ballsPlayed % 6 === 0) {
      return ballsPlayed / 6
    }
    return `${Math.floor(ballsPlayed / 6)}.${Math.floor(ballsPlayed % 6)}`
  }

  if (matchDetailsLoading) {
    return <ShauryaLoader />
  }

  return (
    <div className="match-details-meta">
      {
        matchDetails.winner && (
          <span className="has-background-primary-dark has-text-white is-size-8 has-text-weight-semibold winner-view">
            <i className="fas fa-trophy mr-2"></i> {matchDetails.winner?.team?.name} Won !
          </span>
        )
      }
      <div className="match-item">
        <div className="match-meta-details p-4 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
          <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
            <img
              className="match-team-logo"
              src={matchDetails.team_1.image}
              alt="delhi-avengers" />
            <span className="is-inline-block pc is-size-7 has-text-weight-bold mt-2">
              {matchDetails.team_1_stats.score}/{matchDetails.team_1_stats.out}
              <span className="has-text-grey has-text-weight-semibold is-size-8">
                &nbsp;({getOver(matchDetails.team_1_stats.balls)})
              </span>
            </span>
            <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
              {matchDetails.team_1.name}
            </span>
          </div>
          <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
            <span className="has-text-grey is-size-8 has-text-weight-medium mb-4">
              {matchDetails.name}
            </span>
            <h4 className="sc is-size-5 has-text-weight-bold">VS</h4>
            {
              !matchDetails.winner && (
                <span className="tag blink-tag scb has-text-white">LIVE</span>
              )
            }
          </div>
          <div className="match-item-box is-flex is-flex-direction-column is-align-items-center">
            <img
              className="match-team-logo"
              src={matchDetails.team_2.image}
              alt="delhi-avengers" />
            <span className="is-inline-block pc is-size-7 has-text-weight-bold mt-2">
              {matchDetails.team_2_stats.score}/{matchDetails.team_2_stats.out}
              <span className="has-text-grey has-text-weight-semibold is-size-8">
                &nbsp;({getOver(matchDetails.team_2_stats.balls)})
              </span>
            </span>
            <span className="is-inline-block pc is-size-8 has-text-weight-semibold">
              {matchDetails.team_2.name}
            </span>
          </div>
        </div>
        <div className="match-toss-details has-text-centered pb-2 pt-1">
          <span className="pc is-size-8 has-text-weight-semibold">
            {getTossText()}
          </span>
        </div>
      </div>
    </div>
  )
}

export default MatchMetaDetails;


