import axios from "axios";
import { base_url } from '../config';
import { error_handler } from "../handlers/errorHandler";
import {
    GET_DASHBOARD,
} from "../actionTypes";


//Dashboard Api
export const getDashboard = () => {
    let auth_token = localStorage.getItem('auth_token');
    let config = {
      headers: { 'X-Auth-Token': auth_token }
    };
    return (dispatch) => {
      axios.get(
        base_url + 'dashboard',
        config
      ).then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_DASHBOARD,
            payload: { details: response.data.data }
          })
        }
      }).catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_DASHBOARD,
          payload: {details: null}
        });
      });
    }
  }