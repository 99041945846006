import React, { useState, useEffect } from 'react';
import "./styles.css";
import { images } from '../../images';
import { useHistory } from "react-router-dom";

const MobNavbar = ({ title, subHeading }) => {
  const history = useHistory();
  const [colorChange, setColorchange] = useState(false);

  const [activeRoute, setActiveRoute] = useState('/dashboard')

  useEffect(() => {
    setActiveRoute(window.location.pathname.split("/")[1])
  }, [activeRoute])

  const changeNavbarColor = () => {
    if (window.scrollY >= 30) {
      setColorchange(true);
    }
    else {
      setColorchange(false);
    }
  };

  window.addEventListener('scroll', changeNavbarColor);

  return (
    <div className={colorChange ? 'mob-nav-bar inverted' : 'mob-nav-bar'}>
      {
        ['tournament', 'trial'].includes(activeRoute) && (
          <div className='back-arrow' onClick={() => history.goBack()}>
            <img src={images.arrow_right_png} width="20" alt="arrow_right" />
          </div>
        )
      }
      <h1 className="is-size-6 pc has-text-weight-bold">{title}</h1>
      <p className="is-size-7 pc has-text-weight-semibold">{subHeading}</p>
    </div>
  )
}

export default MobNavbar;