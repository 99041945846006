export const images = {
  user: require('./man.svg'),
  badminton: require('./badminton.svg'),
  tennis: require('./tennis.svg'),
  cricket: require('./cricket.svg'),
  volleyball: require('./volleyball.svg'),
  wrestling: require('./wrestling.svg'),
  kabaddi: require('./kabaddi.svg'),
  basketball: require('./basketball.svg'),
  hockey: require('./hockey.svg'),
  football: require('./football.svg'),
  boxing: require('./boxing.svg'),
  trial_cash: require('./trial_cash.png'),
  trial_counseling: require('./trial_counseling.png'),
  trial_covid: require('./trial_covid.png'),
  trial_kit: require('./trial_kit.png'),
  trial_fitness: require('./trial_fitness.png'),
  trial_leaderboard: require('./trial_leaderboard.png'),
  trial_pitch: require('./trial_pitch.png'),
  trial_score: require('./trial_score.png'),
  trial_shoe: require('./trial_shoe.png'),
  trial_water: require('./trial_water.png'),
  no_trial: require('./no_trial.svg'),
  logo_white: require('./logo-white.png'),
  logo_theme: require('./logo_theme.png'),


  //app icons
  dashboard: require('./dashboard.svg'),
  profile: require('./profile.svg'),
  league: require('./league.svg'),
  notification: require('./notification.svg'),
  menu: require('./menu.svg'),
  top_score: require('./top_score.svg'),
  arrow_right: require('./arrow-right.svg'),
  ranking: require('./ranking.svg'),
  logout: require('./logout.svg'),
  wallet: require('./wallet.svg'),

  //png icons
  dashboard_png: require('./pngicons/dashboard.png'),
  profile_png: require('./pngicons/profile.png'),
  league_png: require('./pngicons/league.png'),
  notification_png: require('./pngicons/notification.png'),
  menu_png: require('./pngicons/menu.png'),
  top_score_png: require('./pngicons/top_score.png'),
  arrow_right_png: require('./pngicons/arrow-right.png'),
  ranking_png: require('./pngicons/ranking.png'),
  logout_png: require('./pngicons/logout.png'),
  wallet_png: require('./pngicons/wallet.png'),
  no_trial: require('./pngicons/no_trial.png'),
  cricket_png: require('./pngicons/cricket.png'),
}
