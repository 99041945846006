const INITIAL_STATE = {
    access_token: undefined,
    is_exists: undefined,
    email_verified: undefined,
    mobile_verified: undefined,
    onboarding: false,
    email: undefined,
    mobile: undefined,
    username: undefined,
    name: undefined,
    age: undefined,
    pincode: undefined,
    speciality: undefined,
    state: undefined,
    city: undefined,
    otpSent: undefined,
    passwordUpdated: undefined,
    message: "",
    loader: {
      login: false,
      signup: false,
      user_exists: false,
      change_password: false,
      onboarding: false
    }
  };
  
  export default INITIAL_STATE;