import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../layout";
import { myPayments } from "../../actions";
import "./styles.css";

const Transactions = () => {
  const dispatch = useDispatch();

  const { transactionsLoading, allTransactions } = useSelector(
    (state) => state.transaction
  );

  useEffect(() => {
    dispatch(myPayments());
  }, []);

  function getPaymentStatus(status, validated) {
    if (status === "captured" && validated) {
      return <span style={{ color: "#16a34a" }}>Amount Received</span>;
    } else if (status === "captured" && !validated) {
      return <span style={{ color: "orange" }}>Amount Processing</span>;
    } else if (status === "authorized") {
      return <span style={{ color: "#2563eb" }}>Amount Initiated</span>;
    } else if (status === "refunded") {
      return <span style={{ color: "darkgrey" }}>Amount Refunded</span>;
    } else {
      return <span style={{ color: "grey" }}>Unable to fetch Amount</span>;
    }
  }

  return (
    <Layout
      loading={transactionsLoading}
      pageTitle="Transactions"
      pageSubtitle="Track your transactions history"
    >
      <div className="transaction-container">
        {allTransactions.length ? (
          allTransactions.map((pay, index) => {
            return (
              <div key={index.toString()} className="card p-3 mt-3">
                <div className="is-flex is-flex-direction-row is-align-items-center">
                  <div className="is-flex-grow-1">
                    <p className="has-text-weight-semibold is-size-6 pc">
                      {pay.payment_id}
                    </p>
                    <p className="has-text-weight-medium is-size-8 mt-1">
                      {new Date(pay.created_at).toLocaleString()}
                    </p>
                  </div>
                  <div className="has-text-right">
                    <p className="is-size-5 has-text-weight-bold pc">
                      ₹ {pay.amount}
                    </p>
                    <p className="is-size-8 has-text-weight-medium has-text-grey">
                      {getPaymentStatus(pay.status, pay.validated)}
                    </p>
                  </div>
                </div>
                <hr />
                <p className="is-size-8 has-text-weight-semibold has-text-grey pc">
                  {pay.description}
                </p>
              </div>
            );
          })
        ) : (
          <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-5">
            <i className="far fa-wallet" />
            <p className="is-size-7 pc mt-2 has-text-weight-semibold">
              No Pending Transactions!
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Transactions;
