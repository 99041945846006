import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Matches from "./matches";
import * as utils from "./utils";
import PoinsTable from "./points-table";
import {
  getMatches,
  getTournamentTeams,
  getTournamentDetails,
  getSelectionStaticContent,
  getTournamentStaticContent,
} from "../../actions";
import TournamentInfo from "./info/tournament";
import SelectionInfo from "./info/selection";
import ChampionshipTeams from "./teams/index";
import { TabBar, MobNavbar } from "../../shared";
import "./styles.css";

const Championship = (props) => {
  const { id } = useParams();
  const eventName = props.location?.state?.name || "Shaurya Tournament";
  const isSelectionMatch = props.location?.state?.isSelectionMatch || false;
  const tabName = props.location?.state?.tabName || "league";
  const dispatch = useDispatch();
  const [colorChange, setColorchange] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabName);
  const TABS = utils.getTabs(isSelectionMatch);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTournamentDetails(id));
    dispatch(getMatches(id));
    dispatch(getTournamentTeams(id));
    dispatch(getSelectionStaticContent(id));
    dispatch(getTournamentStaticContent(id));
  }, []);

  const changeNavbarColor = () => {
    if (window.scrollY >= 30) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const renderTabs = () => {
    return (
      <div
        className={
          colorChange
            ? "championship-tabs is-flex is-flex-direction-row inverted"
            : "championship-tabs is-flex is-flex-direction-row"
        }
      >
        {TABS.map((tab, index) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedTab(tab.key)}
            className={
              tab.key === selectedTab
                ? "championship-tabs-item active"
                : "championship-tabs-item"
            }
            key={index.toString()}
          >
            <span className="is-inline-block is-size-7 pc has-text-weight-bold">
              {tab.value}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const toggleTabComponent = (selectedTab) => {
    selectedTab = selectedTab.toLowerCase();
    switch (selectedTab) {
      case "matches": {
        return <Matches />;
      }
      case "teams": {
        return <ChampionshipTeams />;
      }
      case "league": {
        return <TournamentInfo league={eventName} />;
      }
      case "selection": {
        return <SelectionInfo league={eventName} />;
      }
      case "standings": {
        return <PoinsTable league={eventName} />;
      }
      default: {
        return <TournamentInfo />;
      }
    }
  };

  return (
    <>
      <MobNavbar
        title={eventName}
        subHeading="Live score, streaming & videos"
      />
      {renderTabs()}
      <div className="container mob-league-container">
        {toggleTabComponent(selectedTab)}
      </div>
      <TabBar />
    </>
  );
};

export default Championship;
