import React, { Component } from 'react';

import { Navbar } from '../../shared';

export default class About extends Component{

  render(){
    return(
      <div>
        <Navbar />
        <div className="container">
          <div className="columns is-multiline is-mobile">
            <div className="column is-12" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
              <p className="is-size-2 has-text-weight-bold pc">Page Not Found</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
