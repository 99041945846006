import { combineReducers } from "redux";

import UserReducer from "./user";
import TrialReducer from "./trial";
import MatchReducer from "./match";
import DashboardReducer from "./dashboard";
import AuthReducer from "./authentication";
import TournamentReducer from "./tournament";
import TransactionReducer from "./transaction";

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  trial: TrialReducer,
  match: MatchReducer,
  dashboard: DashboardReducer,
  tournament: TournamentReducer,
  transaction: TransactionReducer,
});

export default rootReducer;
