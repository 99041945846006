import React from 'react';
import { images } from '../images';
const Navbar = () => {
  return (
    <nav
      className={'navbar'}
      role='navigation'
      aria-label='main navigation'>
      <div className='navbar-brand'>
        <a href='/' className='navbar-item'>
          <img src={images.logo_theme} style={{ maxHeight: '56px', width: '56px' }} />
          <span className='logo-text is-flex is-flex-direction-column ml-1'>
            <b className='is-inline-block is-size-7 pc'>SHAURYA</b>
            <b className='is-inline-block is-size-7 pc'>SPORTS</b>
          </span>
        </a>
      </div>
    </nav>
  )
}

export default Navbar;
