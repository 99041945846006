const INITIAL_STATE = {
  allTournaments: [],
  tournamentDetails: {
    allTeams: [],
    matchDetails: {},
    selectionTournaments: [],
    tournamentContent: "",
    selectionContent: "",
  },
  loading: {
    allTournaments: true,
    allTeams: true,
    matchDetails: true,
    tournamentContent: true,
    selectionContent: true,
  },
};

export default INITIAL_STATE;
