import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../tournaments/styles.css";

const TournamentInfo = ({ league }) => {
  let history = useHistory();
  const {
    tournamentDetails: { selectionContent, selectionTournaments },
  } = useSelector((state) => state.tournament);

  const getStatusTag = (status) => {
    if (status === "UPCOMING") {
      return (
        <span className="tag sc  mt-2" style={{ color: "#fff", size: "10px" }}>
          Upcoming
        </span>
      );
    } else if (status === "COMPLETED") {
      return (
        <span
          className="tag mt-2 has-text-weight-semibold"
          style={{ backgroundColor: "#313131", color: "#fff", size: "10px" }}
        >
          Completed
        </span>
      );
    } else {
      return (
        <span
          className="tag blink-tag mt-2"
          style={{ backgroundColor: "#ff1e50", color: "#fff", size: "10px" }}
        >
          Live Now
        </span>
      );
    }
  };

  return (
    <>
      {/* <div className="container tournament-container"> */}
      <div className="container">
        <div className="columns is-flex-wrap-wrap mt-3 league-teams p-2">
          {selectionTournaments.map((event) => {
            return (
              <div
                key={event["_id"]}
                className="column is-4 has-text-centered"
                onClick={() => {
                  history.replace({
                    pathname: `/selection-tournament/${event["_id"]}/matches`,
                    state: {
                      name: event.name,
                      isSelectionMatch: true,
                      tabName: "teams",
                    },
                  });
                  history.go(0);
                }}
              >
                <div
                  className="team-card position-relative"
                  style={{ minHeight: "150px" }}
                >
                  <img
                    src={event.image}
                    alt="team"
                    width="40"
                    className="m-auto"
                  />
                  <h3 className="has-text-weight-bold pc is-size-8 mt-3">
                    {event.name}
                  </h3>
                  <div className="trial-status">
                    {getStatusTag(event.static_data.status)}
                  </div>
                  <div className="trial-stats is-flex is-flex-direction-row is-align-items-center is-justify-content-space-evenly">
                    <div className="">
                      <p className="is-size-7 has-text-weight-bold">
                        {event.matches_count}
                      </p>
                      <p className="is-size-8 has-text-weight-medium has-text-primary-dark">
                        Matches
                      </p>
                    </div>
                    <div>
                      <p className="is-size-7 has-text-weight-bold">
                        {event.teams_count}
                      </p>
                      <p className="is-size-8 has-text-weight-medium has-text-primary-dark">
                        Teams
                      </p>
                    </div>
                    {/* <div>
                        <p className="is-size-7 has-text-weight-bold">{event.players_count}</p>
                        <p className="is-size-8 has-text-weight-medium has-text-primary-dark">Players</p>
                      </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* ---------------------------------------------- */}
      <div className="pl-5 pr-5 mob-about-championship">
        <div
          dangerouslySetInnerHTML={{
            __html: selectionContent,
          }}
        />
      </div>
    </>
  );
};

export default TournamentInfo;
