import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sortBy, reverse } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import ShauryaLoader from "../../../elements/shauryaLoader";

import { images } from "../../../images";

const PointsTable = ({ league }) => {
  const [teams, setTeams] = useState([]);
  const {
    loading: { allTeams: allTeamsLoading },
    tournamentDetails: { allTeams },
  } = useSelector(
    ({
      tournament: {
        loading,
        tournamentDetails: { allTeams },
      },
    }) => ({ loading, tournamentDetails: { allTeams } })
  );

  useEffect(() => {
    if (allTeams.length) {
      let asc_sorted = sortBy(allTeams, "points") || [];
      let sorted_teams = reverse(asc_sorted);
      setTeams(sorted_teams);
    }
  }, [allTeams]);

  if (allTeamsLoading) {
    return <ShauryaLoader />;
  }

  if (!teams.length) {
    return (
      <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-5">
        <img src={images.cricket_png} width="30" />
        <p className="is-size-7 pc mt-2">Teams to be announced soon !</p>
      </div>
    );
  }

  return (
    <>
      <div className="table-container pts_table mt-5">
        <table className="table table is-fullwidth is-borderless">
          <thead>
            <tr className="tbl_header">
              <th className="has-text-weight-bold pc is-size-7">Flag</th>
              <th className="has-text-weight-bold pc is-size-7">Team Name</th>
              <th className="has-text-weight-bold pc is-size-7">Played</th>
              <th className="has-text-weight-bold pc is-size-7">Won</th>
              <th className="has-text-weight-bold pc is-size-7">Points</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((a, index) => (
              <tr className="tbl_rows">
                <td>
                  <img src={a.image} alt="team" width="30" />
                </td>
                <td className="has-text-weight-semibold pc is-size-7">
                  {a.name}
                </td>
                <td className="has-text-weight-semibold pc is-size-7">
                  {league === "North India Championship League"
                    ? "0"
                    : a.played}
                </td>
                <td className="has-text-weight-semibold pc is-size-7">
                  {league === "North India Championship League" ? "0" : a.wins}
                </td>
                <td className="has-text-weight-semibold pc is-size-7">
                  {league === "North India Championship League"
                    ? "0"
                    : a.points}{" "}
                  pts
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PointsTable;
