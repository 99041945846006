import React from "react";
import { useSelector } from "react-redux";
import TopTrialPlayers from "../../../elements/topTrialPlayers";
import { images } from "../../../images";

const TopPlayers = () => {
  const { trialDetails: { top_players, active } } = useSelector(({
    trial: { trialDetails: { top_players, active } } }) => ({ trialDetails: { top_players, active } }));


  if (top_players.length === 0) {
    return (
      <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-5">
        <img src={images.cricket_png} width="30" />
        <p className="is-size-7 pc mt-2">Top Players will be announced soon!</p>
      </div>
    )
  }

  return (
    <div className="mob-top-players">
      <TopTrialPlayers placement="list" list={top_players} />
    </div>
  );
};

export default TopPlayers;
