// Error Handler - Handling API errors

const error_401 = () => {
    if( localStorage.getItem("access_token")) {
      const isMobileWeb = localStorage.getItem("isMobileWeb", "false");
      localStorage.clear();
      localStorage.setItem("isMobileWeb", isMobileWeb);
      window.location = "/auth";
    } 
  }
  
  const error_400 = () => {
    
  }
  
  const error_408 = () => {
    
  }
  
  const error_444 = () => {
    
  }
  
  const error_500 = () => {
    
  }
  
  const error_503 = () => {
    
  }
  
  export const error_handler = (error) => {
    
    let status = error.response ? error.response.status : 0;
    if (status === 401){ // Unauthorized
      error_401();
    } else if (status === 400){ // Bad Request
      error_400();
    } else if (status === 408){ // Request Timeout
      error_408();
    } else if (status === 444){ // No Response - nginx
      error_444();
    } else if (status === 500){ // Internal Server Error
      error_500();
    } else if (status === 503){ // Service Unavailable
      error_503();
    }
  
    return null;
  }