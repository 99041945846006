import React from "react";
import { useSelector } from "react-redux";

const TournamentInfo = ({ league }) => {
  const { tournamentDetails } = useSelector((state) => state.tournament);

  return (
    <div className="pl-5 pr-5 mob-about-championship">
      <div
        dangerouslySetInnerHTML={{
          __html: tournamentDetails.tournamentContent,
        }}
      />
    </div>
  );
};

export default TournamentInfo;
