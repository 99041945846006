import axios from "axios";
import { base_url } from "../config";
import { error_handler } from "../handlers/errorHandler";
import {
  GET_MY_TRIALS,
  GET_ALL_TRIALS,
  GET_TRIAL_DETAILS,
  GET_TOP_TRIAL_PLAYERS,
  GET_TRIAL_STATIC_CONTENT,
} from "../actionTypes";

// check my trails and my score
export const getMyTrails = () => {
  let auth_token = localStorage.getItem("auth_token");
  let config = {
    headers: { "X-Auth-Token": auth_token },
  };
  return (dispatch) => {
    axios
      .get(base_url + "my_trials", config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_MY_TRIALS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_MY_TRIALS,
          payload: {},
        });
      });
  };
};

// check all Trials
export const getAllTrails = () => {
  return (dispatch) => {
    axios
      .get(
        base_url + "trials"
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_TRIALS,
            payload: { all_trials: response.data.data.trials },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_ALL_TRIALS,
          payload: {},
        });
      });
  };
};

// check Trial Details
export const getTrailDetails = (trialId) => {
  return (dispatch) => {
    axios
      .get(
        base_url + "trial/" + trialId
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response", response);
          dispatch({
            type: GET_TRIAL_DETAILS,
            payload: { trial_details: response.data.data.trial },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_TRIAL_DETAILS,
          payload: {},
        });
      });
  };
};

// check Static Content
export const getTrailStaticContent = (trialId) => {
  return (dispatch) => {
    axios
      .get(
        base_url + "trial_details/" + trialId
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response", response);
          dispatch({
            type: GET_TRIAL_STATIC_CONTENT,
            payload: { static_content: response.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_TRIAL_STATIC_CONTENT,
          payload: {},
        });
      });
  };
};
// check top trials players
export const getTopTrialPlayers = () => {
  return (dispatch) => {
    axios
      .get(
        base_url + "trials_top_players"
        // config
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: GET_TOP_TRIAL_PLAYERS,
            payload: { ...response.data.data },
          });
        }
      })
      .catch(function (error) {
        error_handler(error);
        dispatch({
          type: GET_TOP_TRIAL_PLAYERS,
          payload: {},
        });
      });
  };
};
