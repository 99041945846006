import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getMatchDetails } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import ShauryaLoader from "../../../../elements/shauryaLoader";

import MatchMetaDetails from "./meta-details";
import Innings1Details from "./match-inning-details/innings1-details";
import Innings2Details from "./match-inning-details/innings2-details";
import MatchInfo from "./match-info";
import { images } from "../../../../images";

const MatchDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const {
    loading: { matchDetails: matchDetailsLoading },
    matchDetails,
  } = useSelector(({ match: { loading, matchDetails } }) => ({
    loading,
    matchDetails,
  }));
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const id = params.id;
    dispatch(getMatchDetails(id));
    if (!matchDetailsLoading) {
      setActiveTab(matchDetails.team_1.name);
    }
  }, [matchDetailsLoading]);

  return (
    <div className="match-details-wrapper">
      <div
        style={{ backgroundColor: "#fff", padding: 8, borderRadius: "50%" }}
        className="back-to-list"
        onClick={() => history.goBack()}
      >
        <img src={images.arrow_right_png} width="18" alt="arrow_right" />
      </div>
      <div className="match-top-background" />
      <MatchMetaDetails />
      <div className="match-inning-wrapper mb-5">
        {!matchDetailsLoading ? (
          <>
            <div className="tabs is-toggle is-toggle-rounded is-centered mt-5 match-details-tab">
              <ul>
                <li
                  onClick={() => setActiveTab(matchDetails.team_1.name)}
                  className={
                    activeTab === matchDetails.team_1.name ? "is-active" : ""
                  }
                >
                  <a className="pc has-text-weight-semibold">
                    {matchDetails.team_1.name}
                  </a>
                </li>
                <li
                  onClick={() => setActiveTab(matchDetails.team_2.name)}
                  className={
                    activeTab === matchDetails.team_2.name ? "is-active" : ""
                  }
                >
                  <a className="pc has-text-weight-semibold">
                    {matchDetails.team_2.name}
                  </a>
                </li>
              </ul>
            </div>
            {activeTab === matchDetails.team_1.name ? (
              <Innings1Details />
            ) : (
              <Innings2Details />
            )}
          </>
        ) : null}
      </div>
      {/* <MatchInfo /> */}
    </div>
  );
};

export default MatchDetails;
