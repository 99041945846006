import React, { Component } from "react";
import { connect } from "react-redux";

import { TabBar, MobNavbar } from "../../shared";
import { getProfile, updateProfile } from "../../actions";
import { states, cities } from "../../data.js";
import {
  sportsDisplayName,
  genderDisplayName,
  sportsRoleDisplayName,
  sportsCategories,
  activeSports,
  professionOptions,
  sportsRole,
} from "../../constants/mappings";
import "./styles.css";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      state_id: "",
      editEanble: false,
      loading: true,
      confirmationModal: false,
    };
  }

  componentDidMount = () => {
    let token = localStorage.getItem("auth_token");
    if (!token) {
      this.props.history.push("/");
    } else {
      this.props.getProfile();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.profile !== this.props.profile) {
      const profile = this.props.profile || {};
      let state_id = "";
      if (profile.state) {
        let index = states.findIndex(
          (state) => state.state_name === profile.state
        );
        if (index > -1) {
          state_id = states[index]["state_id"];
        }
      }
      this.setState({
        profile: { ...this.state.profile, ...this.props.profile },
        state_id: state_id,
        loading: false,
        editEanble: false,
      });
    }
  };

  handleInputs = (e) => {
    this.setState({
      profile: {
        ...this.state.profile,
        [e.target.name]: e.target.value,
      },
    });
  };

  setSpeciality = (event) => {
    this.setState({
      profile: {
        ...this.state.profile,
        speciality: event.target.value,
      },
    });
  };

  onPickerChange = (val, key) => {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: val.target.value,
      },
    });
  };

  cancelEditing = () => {
    this.setState({
      profile: { ...this.props.profile },
      editEanble: false,
    });
  };

  renderLoding = () => {
    return (
      <div className="columns is-multiline  profile-wrapper">
        <div className="column d-flex text-center" style={{ margin: "30px 0" }}>
          <span className="pc is-size-7">Loading your profile...</span>
        </div>
      </div>
    );
  };

  getDefaultSpeciality = () => {
    const {
      profile: { speciality, sport },
    } = this.state;
    let index = sportsRole[sport].findIndex((spec) => spec === speciality);
    if (index > -1) {
      return sportsRole[sport][index];
    }
    return "";
  };

  onStateChange = (e) => {
    states.find((element) => {
      if (element.state_name === e.target.value) {
        const state_id = element.state_id;
        this.setState({
          state_id,
          profile: {
            ...this.state.profile,
            state: element.state_name,
          },
        });
      }
      return null;
    });
  };

  updateUserProfile = () => {
    let data = this.state.profile;
    let {
      name,
      age,
      pincode,
      speciality,
      state,
      city,
      gender,
      sport,
      profession,
    } = data;
    if (
      name &&
      age &&
      pincode &&
      speciality &&
      state &&
      city &&
      gender &&
      sport &&
      profession
    ) {
      let dataObj = {
        name: name,
        age: age,
        pincode: pincode,
        speciality: speciality,
        state: state,
        city: city,
        gender: gender,
        sport: sport,
        profession: profession,
      };
      this.props.updateProfile(dataObj);
    } else {
      window.error("All feilds marked as * are mandatory !");
    }
  };

  renderProfileForm = () => {
    const { profile, editEanble } = this.state;
    const {
      age,
      city,
      email,
      email_verified,
      gender,
      mobile,
      mobile_verified,
      name,
      onboarding,
      pincode,
      profession,
      speciality,
      sport,
      state,
      username,
    } = profile;

    return (
      <div className="column is-5">
        <h2 className="mb-5 is-size-5 has-text-weight-bold has-text-centered pc coach-title">
          Edit Basic Information
        </h2>

        {/*...............Name...............*/}
        <div className="field">
          <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
            Full Name *
          </p>
          <div className="control">
            <input
              className="input is-medium"
              name="name"
              onChange={this.handleInputs}
              type="text"
              value={name}
              placeholder="Eg - Sachin Tendulkar"
            />
          </div>
        </div>

        {/*...............User Name...............*/}

        <div>
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              User Name *
            </p>
            <div className="control">
              <input
                className="input is-medium"
                name="name"
                onChange={() => {}}
                disabled
                type="text"
                value={username}
              />
            </div>
          </div>

          {/*...............User Name...............*/}

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Registered Mobile *
            </p>
            <div className="control">
              <input
                className="input is-medium"
                name="mobile"
                onChange={this.handleInputs}
                type="text"
                disabled
                value={mobile}
                placeholder="7078776543"
              />
            </div>
          </div>

          {/*...............EMAIL...............*/}

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Email *
            </p>
            <div className="control">
              <input
                className="input is-medium"
                name="email"
                onChange={this.handleInputs}
                type="text"
                disabled
                value={email}
                placeholder="Eg - someone@shaurya.com"
              />
            </div>
          </div>

          {/*...............Gender...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"GENDER".getText()} *
            </p>
            <div className="select mb-2" style={{ width: "100%" }}>
              <select
                className="input is-medium"
                disabled={editEanble ? false : true}
                defaultValue={gender}
                required
                style={{ width: "100%" }}
                onChange={(val) => {
                  this.onPickerChange(val, "gender");
                }}
              >
                <option value="" defaultValue data-default>
                  {"Select your Gender".getText()}
                </option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
              </select>
            </div>
          </div>

          {/*...............Age...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"Your Age".getText()} *
            </p>
            <div className="control">
              <input
                name="age"
                className="input is-medium"
                type="text"
                value={age}
                onChange={this.handleInputs}
                placeholder="eg - 38"
              />
            </div>
          </div>

          {/*...............Profession...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"PROFESSION".getText()} *
            </p>
            <div className="select mb-2" style={{ width: "100%" }}>
              <select
                className="input is-medium"
                defaultValue={profession}
                required
                style={{ width: "100%" }}
                onChange={(val) => {
                  this.onPickerChange(val, "profession");
                }}
              >
                <option value="" defaultValue data-default>
                  {"Select your Profession".getText()}
                </option>
                {professionOptions.map((role, index) => (
                  <option key={index} value={`${role}`}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/*...............Enrolling For...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"ENROLLING FOR".getText()} *
            </p>
            <div className="select mb-2" style={{ width: "100%" }}>
              <select
                className="input is-medium"
                defaultValue={sport}
                required
                style={{ width: "100%" }}
                onChange={(val) => {
                  this.onPickerChange(val, "sport");
                }}
              >
                <option value="" defaultValue data-default>
                  {"Select your Sport".getText()}
                </option>
                {sportsCategories.map((sport, index) => (
                  <option
                    key={index}
                    disabled={activeSports.includes(sport) ? false : true}
                    value={sport}
                  >
                    {sportsDisplayName[sport]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/*............... Speciality ...............*/}

          {sport ? (
            <div className="field">
              <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
                {"Applying As".getText()} *
              </p>
              <div className="select mb-2" style={{ width: "100%" }}>
                <select
                  className="input is-medium"
                  defaultValue={this.getDefaultSpeciality()}
                  required
                  style={{ width: "100%" }}
                  onChange={(val) => {
                    this.setSpeciality(val);
                  }}
                >
                  <option value="" defaultValue data-default>
                    Select your Role
                  </option>
                  {sportsRole[sport].map((role, index) => (
                    <option key={index} value={role}>
                      {`${sportsRoleDisplayName[role]}`.getText()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}

          {/*............... State ...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"State".getText()} *
            </p>
            <div className="select mb-2" style={{ width: "100%" }}>
              <select
                className="input is-medium"
                defaultValue={state}
                required
                style={{ width: "100%" }}
                onChange={this.onStateChange}
              >
                {states.map((state, index) => (
                  <option key={index} value={`${state.state_name}`}>
                    {state.state_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/*............... City ...............*/}
          {
            <div className="field">
              <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
                {"City".getText()} *
              </p>
              <div className="select mb-2" style={{ width: "100%" }}>
                <select
                  defaultValue={city}
                  className="input is-medium"
                  style={{ width: "100%" }}
                  name="city"
                  onChange={this.handleInputs}
                >
                  <option value="" disabled defaultValue hidden>
                    {"Select your City".getText()}
                  </option>
                  {cities.map((city, index) => {
                    return city.city_id === this.state.state_id ? (
                      <option key={index} value={`${city.city_name}`}>
                        {city.city_name}
                      </option>
                    ) : null;
                  })}
                </select>
              </div>
            </div>
          }
          {/*...............PinCode...............*/}
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              {"PIN CODE".getText()} *
            </p>
            <div className="control">
              <input
                name="pincode"
                className="input is-medium"
                type="text"
                value={pincode}
                onChange={this.handleInputs}
                placeholder="eg - 133001"
              />
            </div>
          </div>

          <div
            className="mt-4"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <button
              style={{ width: "40%" }}
              onClick={this.updateUserProfile}
              className="button is-link"
            >
              Update
            </button>
            <button
              onClick={this.cancelEditing}
              style={{ width: "40%" }}
              className="button is-danger"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderProfileDetails = () => {
    const {
      age,
      city,
      email,
      email_verified,
      gender,
      mobile,
      mobile_verified,
      name,
      onboarding,
      pincode,
      profession,
      speciality,
      sport,
      state,
      username,
    } = this.state.profile;

    return (
      <div className="column is-5">
        <h2 className="mb-5 is-size-5 has-text-centered has-text-weight-bold pc coach-title">
          Basic Information
        </h2>
        <div>
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Full Name
            </p>
            <input className="input is-medium" disabled value={name} />
          </div>
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              User Name
            </p>
            <input className="input is-medium" disabled value={username} />
          </div>
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Registered Mobile
            </p>
            <input className="input is-medium" disabled value={mobile} />
          </div>
          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Email
            </p>
            <input className="input is-medium" disabled value={email} />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Gender
            </p>
            <input
              className="input is-medium"
              disabled
              value={genderDisplayName[gender]}
            />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Age
            </p>
            <input className="input is-medium" disabled value={age} />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Profession
            </p>
            <input className="input is-medium" disabled value={profession} />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Enrolled In
            </p>
            <input
              className="input is-medium"
              disabled
              value={sportsDisplayName[sport]}
            />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Speciality
            </p>
            <input
              className="input is-medium"
              disabled
              value={sportsRoleDisplayName[speciality]}
            />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              State
            </p>
            <input className="input is-medium" disabled value={state} />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              City
            </p>
            <input className="input is-medium" disabled value={city} />
          </div>

          <div className="field">
            <p className="has-text-grey-light is-size-7 mb-2 form_label is-uppercase has-text-weight-semibold">
              Pincode
            </p>
            <input className="input is-medium" disabled value={pincode} />
          </div>
        </div>
      </div>
    );
  };

  renderProfileScreen = () => {
    const { profile, editEanble } = this.state;
    const { age, city, gender, name, speciality, state } = profile;

    return (
      <div className="columns is-multiline p-5">
        <div className="column mb-5 is-3 is-offset-2 text-center">
          <figure
            style={{ margin: "0px auto" }}
            className="image is-128x128 mob-profile-avatar"
          >
            {gender === "MALE" ? (
              <img
                className="is-rounded profile-avatar"
                src="https://semantic-ui.com/images/avatar2/large/matthew.png"
              />
            ) : (
              <img
                className="is-rounded profile-avatar"
                src="https://semantic-ui.com/images/avatar2/large/kristy.png"
              />
            )}
          </figure>
          <div>
            <p className="is-size-4 has-text-weight-bold mt-4 has-text-white">
              <span className="pc">{name ? name : "Shaurya Member"}</span>,{" "}
              <span className="has-text-grey-light">{age ? age : ""}</span>
            </p>
            <p className="pc has-text-weight-semibold">
              (
              {speciality
                ? sportsRoleDisplayName[speciality]
                : "Speciality not mentioned"}
              )
            </p>
            <p className="has-text-weight-semibold pc">
              <span>{city ? city : ""}</span>, {state ? state : ""}
            </p>
          </div>
          {editEanble ? null : (
            <>
              <button
                className="is-rounded button is-link is-small mt-3 has-text-weight-semibold"
                onClick={() => {
                  this.setState({ editEanble: true });
                }}
              >
                Edit Profile
              </button>
            </>
          )}
        </div>

        {editEanble ? this.renderProfileForm() : this.renderProfileDetails()}
      </div>
    );
  };

  render() {
    return (
      <div>
        <MobNavbar
          title="Profile"
          subHeading="Edit your speciality & basic information"
        />
        <div className="container mob-profile-container">
          {this.state.loading
            ? this.renderLoding()
            : this.renderProfileScreen()}
        </div>
        <TabBar />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    profile: user.profile,
  };
};

export default connect(mapStateToProps, { getProfile, updateProfile })(Profile);
